import React from 'react';
import I18n from '../../language';
import SvgIcon from '../../components/SvgIcon';
import { useSelector } from 'react-redux';
import chartImgCn from '../../assets/imgs/detail/chart_cn.png';
import chartImgEn from '../../assets/imgs/detail/chart_en.png';
import otherImgEn from '../../assets/imgs/detail/chart_en_other.png';
import otherImgCn from '../../assets/imgs/detail/chart_cn_other.png';
import vsImg from '../../assets/imgs/detail/vs.png';

export default function BreakEvent({ item }) {
   const { language } = useSelector(store => store.user);
   return (
      <div className="section-break-event">
         <div className="box-cell mb40 alert">
            <div className="item-title">{I18n.t('套餐简介')}</div>
            <p className="text">{item.package_introduction}</p>
         </div>
         <div className="box-cell mb40 break-event-wp">
            <div className="item-title mb25">{I18n.t('产品优势')}</div>
            <div className="content">
               <div className="title">{I18n.t('BTC减半下行行情产出率测算')}</div>
               <ul>
                  <li>
                     <SvgIcon name="select" />
                     <span>{I18n.t('套餐周期：120天-240天')}</span>
                  </li>
                  <li>
                     <SvgIcon name="select" />
                     <span>{I18n.t('产出减半')}</span>
                  </li>
                  <li>
                     <SvgIcon name="select" />
                     <span>{I18n.t('BTC价格降至44000~30000USDT')}</span>
                  </li>
               </ul>
               <div className="chart">
                  <div className="top">
                     <div className="left">
                        <div className="text">{I18n.t('Bitroo保本套餐')}</div>
                        <div className="text">
                           {I18n.t(
                              '到期未回本自动延期，持续兑付，直到累计产出收益超过用户成本，用户既能获得收益又能避免不回本风险。'
                           )}
                        </div>
                     </div>
                     <div className="right">
                        <img src={language === 'en' ? chartImgEn : chartImgCn} alt="" />
                        <div className="text">{I18n.t('产出率')} ≥ 100%</div>
                     </div>
                  </div>
                  <div className="bottom">
                     <div className="left">
                        <div className="text">{I18n.t('市面其他套餐')}</div>
                        <div className="text">
                           {I18n.t(
                              '按当前币价计算回本数据并定价，因前期成本已经支付，套餐到期结束，停止产出，用户面临不回本风险。'
                           )}
                        </div>
                     </div>
                     <div className="right">
                        <img src={language === 'en' ? otherImgEn : otherImgCn} alt="" />
                        <div className="text">{I18n.t('产出率')} 60~80%</div>
                     </div>
                  </div>
                  <img src={vsImg} alt="" className="vs-icon" />
               </div>
            </div>
         </div>
      </div>
   );
}
