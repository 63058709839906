import React, { forwardRef } from 'react';
import I18n from '../../language';
import { useSelector } from 'react-redux';
import { Collapse, theme } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import SvgIcon from '../../components/SvgIcon';

const Help = forwardRef((props, ref) => {
   const { language } = useSelector(store => store.user);
   function handleMore() {
      window.open(`https://bitroo.zendesk.com/hc/${language === 'zh' ? 'zh-cn' : 'en-001'}`);
   }
   const panelStyle = {
      marginBottom: 20,
      border: 'none',
      background: '#fff',
      borderRadius: '20px',
   };
   const getItems = [
      {
         key: '1',
         label: `1. ${I18n.t('什么是算力租赁套餐')}？`,
         children: <p>{I18n.t('常见问题1')}</p>,
         style: panelStyle,
      },
      {
         key: '2',
         label: `2. ${I18n.t('如何在Bitroo购买算力租赁套餐')}？`,
         children: <p>{I18n.t('常见问题2')}</p>,
         style: panelStyle,
      },
      {
         key: '3',
         label: `3. ${I18n.t('算力产品的费用包括哪几部分')}？`,
         children: <p>{I18n.t('常见问题3')}</p>,
         style: panelStyle,
      },
   ];
   return (
      <div className="help view" ref={ref}>
         <h2 style={{ textAlign: 'center' }}>{I18n.t('常见问题')}</h2>
         <Collapse
            bordered={false}
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
               // <SvgIcon name={isActive ? 'expand' : 'retract'} rotate={isActive ? 90 : 0} />
               <DownOutlined rotate={isActive ? 180 : 0} />
            )}
            items={getItems}
         />
         <div className="more">
            <div className="item" onClick={handleMore}>
               <span>{I18n.t('更多')}</span>
               <SvgIcon name="more" />
            </div>
         </div>
      </div>
   );
});

export default Help;
