import React, { useState, useEffect } from 'react';
import { Button, Form, message, Tabs } from 'antd';
import Input from '../../components/Input';
import { useNavigate } from 'react-router-dom';
import img3 from '../../assets/imgs/header/logo_2.png';
import luckImg from '../../assets/imgs/register/luck.png';
import { sendCode, register, getRegisterInfo } from '../../services/user';
import { isEmailAddress, queryUrlParams } from '../../utils/index';
import I18n from '../../language';
import FinishModal from './finishModal';
import Header from '../../components/Header';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../store/user';

function Register() {
   const dispatch = useDispatch();
   const { language } = useSelector(store => store.user);
   function handleToggle() {
      dispatch(setLanguage(language === 'zh' ? 'en' : 'zh'));
      getRegisterCouponVal({ code: formData.promotionCode });
   }
   const headerClickHandler = key => {
      navigate('/home/' + key);
   };
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   // const [count, setCount] = useState(61);
   // const [email, setEmail] = useState('');
   const [finishModalShow, setFinishModalShow] = useState(false);
   const tabsList = [
      {
         label: I18n.t('邮箱注册'),
         key: 'email',
      },
      {
         label: I18n.t('手机号注册'),
         key: 'tel',
      },
   ];
   const locationCode = queryUrlParams('code') || '';
   const dropPacket = queryUrlParams('dropPacket') || '';
   const [welfareMsg, setWelfareMsg] = useState({ title: '', content: [] });
   const formRef = React.useRef(null);
   const [formData, setFormData] = useState({
      areaCode: 86,
      email: '',
      tel: '',
      password: '',
      promotionCode: locationCode,
      accountType: 'email',
      code: '',
      dropPacket,
   });
   const baseForm = [
      {
         type: 'email',
         key: 'email',
         placeholder: I18n.t('请输入邮箱'),
         unrender: formData.accountType !== 'email',
         rules: [
            {
               required: true,
               validator(rule, value, callback) {
                  if (!isEmailAddress(value)) {
                     callback(new Error(I18n.t('请输入正确格式的邮箱')));
                     return;
                  }
                  callback();
               },
            },
         ],
         props: {
            maxLength: 100,
         },
      },
      {
         type: 'tel',
         key: 'tel',
         rules: [
            {
               required: true,
               validator(rule, value, callback) {
                  if (/[^0-9]+/.test(value)) {
                     callback(new Error(I18n.t('请输入正确格式的手机号')));
                     return;
                  }
                  callback();
               },
            },
         ],
         placeholder: I18n.t('请输入手机号'),
         unrender: formData.accountType !== 'tel',
         props: {
            maxLength: 11,
         },
      },
      {
         type: 'code',
         key: 'code',
         placeholder: I18n.t('请输入验证码'),
         rules: [{ required: true, message: I18n.t('验证码不能为空') }],
         props: {
            maxLength: 6,
         },
      },
      {
         type: 'password',
         key: 'password',
         rules: [
            {
               required: true,
               validator(rule, value, callback) {
                  if (!checkPassword(value)) {
                     callback(new Error(I18n.t('登录密码大于8位')));
                     // callback(new Error(I18n.t('登录密码为8-16位，数字字母符号组合')));
                  }
                  callback();
               },
            },
         ],
         placeholder: I18n.t('请输入密码'),
      },
      {
         type: 'text',
         key: 'promotionCode',
         placeholder: I18n.t('请输入邀请码（选填）'),
         isInput: true,
         props: {
            disabled: !!locationCode,
         },
      },
   ];
   const checkPassword = val => {
      // let reg = new RegExp(
      //    /^(?=.*[^a-zA-Z])(?=.*\d)(?=.*[\~\!\@\#\$\%\^\&\*\(\)\_\+\|\-\=\\\[\]\{\}\;\'\:\"\,\.\/\<\>\?]).{8,16}$/
      // );
      const reg = new RegExp(/^.{8,}$/);
      if (
         val == null ||
         val.length < 8 ||
         /\s/gi.test(val) ||
         /[\u4e00-\u9fa5]/.test(val) ||
         !reg.test(val)
      ) {
         return false;
      }
      return true;
   };

   // function onFinish(values) {
   //    const cancel = message.loading(I18n.t('正在注册'), 0);
   //    setLoading(true);
   //    try {
   //       register({ accountType: 'email', ...values }).then(res => {
   //          if (res.data.code === 0) {
   //             message.success(I18n.t('注册成功'));
   //             navigate('/home/4');
   //          } else {
   //             message.warning(res.data.message);
   //          }
   //       });
   //    } finally {
   //       cancel();
   //       setLoading(false);
   //    }
   // }

   // // 定时器
   // function startTimer() {
   //    let timer = setInterval(() => {
   //       setCount(pre => {
   //          if (pre > 0) {
   //             return pre - 1;
   //          } else {
   //             if (timer !== null) {
   //                clearInterval(timer);
   //             }
   //             return 61;
   //          }
   //       });
   //    }, 1000);
   // }

   async function handleSend() {
      const { accountType, areaCode } = formData;
      const account = formData[accountType];
      if (accountType === 'email') {
         if (!account.trim()) {
            message.warning(I18n.t('邮箱不能为空'));
            return;
         }
         if (!isEmailAddress(account)) {
            message.warning(I18n.t('请输入正确格式的邮箱'));
            return;
         }
      }
      if (accountType === 'tel') {
         if (!account.trim()) {
            message.warning(I18n.t('请输入正确格式的手机号'));
            return;
         }
      }

      const parmas = {
         accountType,
         authType: 'register',
         account,
         areaCode: accountType === 'email' ? '' : `${areaCode}`,
      };

      const res = await sendCode(parmas);
      if (res.data.code === 0) {
         message.success(I18n.t('发送成功'));
         return 'success';
      } else {
         message.warning(res.data.message);
      }
   }
   const [currentMine, setCurrentMine] = useState(0);
   const doRegist = async () => {
      if (loading) {
         return;
      }
      const { accountType, areaCode, code, password, promotionCode, dropPacket } = formData;
      const account = formData[accountType];
      if (accountType === 'email' && !isEmailAddress(account)) {
         if (!account.trim()) {
            message.error(I18n.t('邮箱不能为空'));
            return;
         }
         message.error(I18n.t('请输入正确格式的邮箱'));
         return;
      }
      if (accountType === 'tel' && !account.trim()) {
         message.error(I18n.t('手机号不能为空'));
         return;
      }
      if (!code || !code.trim()) {
         message.error(I18n.t('验证码不能为空'));
         return;
      }
      if (!checkPassword(password)) {
         // message.error(I18n.t('登录密码大于8位'));
         message.error(I18n.t('登录密码为8-16位，数字字母符号组合'));
         return;
      }

      const cancel = message.loading(I18n.t('正在注册'), 0);
      const params = {
         accountType,
         dropPacket,
         account,
         code,
         promotionCode,
         password,
         areaCode: accountType === 'tel' ? `${areaCode}` : '',
      };

      try {
         const res = await register(params);
         if (res.data?.code === 0) {
            message.success(I18n.t('注册成功'));
            setCurrentMine(res.data?.data);
            setFinishModalShow(true);
            // navigate('/home/download');
         } else {
            message.warning(res.data.message);
         }
      } finally {
         cancel();
         setLoading(false);
      }
   };

   const valueChange = (text, key) => {
      const val = {
         ...formData,
         [key]: text,
      };
      setFormData(val);
   };
   const tabChange = key => {
      // formRef.current.resetFields();
      const val = {
         ...formData,
         accountType: key,
      };
      setFormData(val);
      // setTimeout(() => {
      //    formRef.current.setFieldsValue(val);
      // }, 0);

      // formRef.current.setFieldsValue(val);
   };

   const goAgreement = () => {
      window.open(`https://user-agreement.bitroo.com/#/userAgreement/${I18n.locale}`);
   };
   const [registerInfo, setRegisterInfo] = useState({});
   const getRegisterCouponVal = ({ code }) => {
      getRegisterInfo({
         promotionCode: code,
         dropPacket,
      }).then(res => {
         if (res.data.code === 0) {
            setRegisterInfo(res.data.data);
         }
      });
   };
   const adJump = url => {
      if (url) {
         window.open(url);
      }
   };
   useEffect(() => {
      getRegisterCouponVal({ code: formData.promotionCode });
   }, []);
   return (
      <div className="register">
         {/* <div className="top">
            <img src={img1} alt="" className="img" onClick={() => navigate('/')} />
         </div> */}
         <Header
            onChangeLang={() => handleToggle()}
            onClick={key => headerClickHandler(key)}
            theme="dark"
         />
         <div className="content">
            <div className="left">
               <div className="title text">{registerInfo.title}</div>
               <div className="sub-title text">{registerInfo.subTitle}</div>
               <img src={luckImg} alt=""></img>
               {registerInfo.picture ? (
                  <div
                     className="banner"
                     onClick={() => {
                        adJump(registerInfo.jumpUrl);
                     }}
                     style={{
                        cursor: registerInfo.jumpUrl ? 'pointer' : 'auto',
                     }}
                  >
                     <img src={registerInfo.picture} alt="" />
                  </div>
               ) : null}
            </div>
            <div className="right">
               <div className="regist-header">
                  <img src={img3} alt="" className="topImg" />
                  <span className="title">{I18n.t('注册Bitroo 账号，了解更多')}</span>
               </div>
               <div className="regist-tab">
                  <Tabs
                     onChange={key => tabChange(key)}
                     items={tabsList}
                     centered
                     animated={false}
                  ></Tabs>
               </div>
               <Form ref={formRef} initialValues={formData}>
                  {baseForm.map(item => {
                     if (item.unrender) {
                        return null;
                     }
                     return (
                        <Form.Item key={`${item.key}`} name={item.key} rules={item.rules}>
                           {item.isInput ? (
                              <input
                                 className="input-view"
                                 key={`${item.key}`}
                                 type={item.type}
                                 placeholder={item.placeholder}
                                 {...item.props}
                                 name={item.key}
                                 onChange={text => valueChange(text.target.value, item.key)}
                              ></input>
                           ) : (
                              <Input
                                 key={`${item.key}`}
                                 type={item.type}
                                 placeholder={item.placeholder}
                                 onChange={text => valueChange(text, item.key)}
                                 sendCodeFun={handleSend}
                                 onAreaChange={code => valueChange(code, 'areaCode')}
                                 {...item.props}
                                 name={item.key}
                              ></Input>
                           )}
                        </Form.Item>
                     );
                  })}
               </Form>

               <div className="agreen">
                  <span>{I18n.t('注册表明您已同意')}</span>
                  <span onClick={goAgreement}>《{I18n.t('用户协议')}》</span>
               </div>

               <div className="btn-box">
                  <Button onClick={doRegist} type="text" className="btn" loading={loading}>
                     {registerInfo.buttonName ?? I18n.t('注册')}
                  </Button>
               </div>
            </div>
         </div>
         <FinishModal show={finishModalShow} mineNumber={currentMine} />
      </div>
   );
}
export default Register;
