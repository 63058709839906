import request from '../utils/request';

//注册
export function register(data) {
   return request('/v1/auth/register', {
      method: 'POST',
      data,
   });
}

//发送验证码
export function sendCode(data) {
   return request('/v1/auth/send-captcha', {
      method: 'POST',
      data,
   });
}

//获取云矿机列表
export function getCloudMiner(params) {
   return request('/v1/cloud-miner/list', {
      method: 'GET',
      params,
   });
}
// 获取矿机
export function getMiner(params) {
   return request('/n/api/v1/miner/list', {
      method: 'GET',
      params,
   });
}

//获取云矿机详情
export function getCloudMinerDetail(params) {
   return request('/v1/cloud-miner/details', {
      method: 'GET',
      params,
   });
}

//获取回本天数
export function getRecoveryDays(data) {
   return request('/v1/cloud-miner/static-output-reach', {
      method: 'POST',
      data,
   });
}

//获取静态产出率
export function getStaticOutput(data) {
   return request('/v1/cloud-miner/static-output', {
      method: 'POST',
      data,
   });
}
// 获取奖励信息
export function getRegisterCoupon(params) {
   return request('/n/api/v1/coupon/getRegisterCoupon', {
      method: 'GET',
      params,
   });
}
// 注册页信息
export function getRegisterInfo(params) {
   return request('/n/api/v1/register/info', {
      method: 'GET',
      params,
   });
}

//套餐类型
export const typeEnum = {
   speeded: {
      text: '加速套餐',
      color: '#E66557',
   },
   output: {
      text: '全产出套餐',
      color: '#E66557',
   },
   classic: {
      text: '经典套餐',
      color: '#52AE97',
   },
   // guaranteed: {
   //    text: '保本套餐',
   //    color: '#E66557',
   // },
};
//获取静态产出率
export function getArea() {
   return request('/v1/auth/areas', {
      method: 'GET',
   });
}
