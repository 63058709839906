import { useNavigate } from 'react-router-dom';
import I18n from '../../../language';
export default function DownloadModal({ show, closeHandler }) {
   const navigate = useNavigate();
   const finishHandler = () => {
      navigate('/download');
   };
   return show ? (
      <div className="download-modal">
         <div className="center">
            <div className="label">{I18n.t('想要了解更多矿机信息，请下载 Bitroo APP')}</div>
            <div className="btn-wp">
               <div onClick={closeHandler}>{I18n.t('我知道了')}</div>
               <div
                  onClick={() => {
                     finishHandler();
                     closeHandler();
                  }}
               >
                  {I18n.t('去下载')}
               </div>
            </div>
         </div>
      </div>
   ) : null;
}
