import React from 'react';
import SelectArea from './selectArea';
import './index.scss';
import searchPng from '../../assets/imgs/search_area.png';
import I18n from '../../language';

const Input = ({
   type = 'text',
   value,
   onChange,
   style = {},
   className,
   prefix,
   sendCodeFun,
   areaCode,
   placeholder,
   onAreaChange,
   maxLength,
   name,
}) => {
   const initTime = 60;
   const [innerValue, setInnerValue] = React.useState('');
   const [isFocus, setIsFocus] = React.useState(false);
   const [isShowArea, setIsShowArea] = React.useState(false);
   const [innerAreaCode, setInnerAreaCode] = React.useState(areaCode || 86);
   const [isSending, setIsSending] = React.useState(false);
   const inputRef = React.useRef(null);
   const [info, setInfo] = React.useState({
      time: initTime,
      innerType: 'text',
   });

   document.addEventListener('mousemove', () => {
      setIsShowArea(false);
   });

   React.useEffect(() => {
      let reset = {};
      switch (type) {
         case 'password':
            reset = {
               type: 'password',
            };
            break;
         default:
            reset = {};
      }
      setInfo({
         ...info,
         ...reset,
      });
   }, []);

   React.useEffect(() => {
      if (value !== innerValue) {
         inputRef.current.value = value;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [value]);

   const valueChange = evt => {
      let val = evt.target.value;
      if (type === 'tel' || type === 'code') {
         val = val.replace(/[^0-9]+/, '');
      }
      setInnerValue(val);
      if (onChange && typeof onChange === 'function') {
         onChange(val);
      }
   };

   const sendCode = async () => {
      if (
         info.isSending ||
         !sendCodeFun ||
         typeof sendCodeFun !== 'function' ||
         isSending ||
         info.time !== initTime
      ) {
         return;
      }
      setIsSending(true);
      try {
         const res = await sendCodeFun();
         if (res === 'success') {
            cutDown();
         }
      } finally {
         setIsSending(false);
      }
   };
   const cutDown = () => {
      if (info.time <= 0) {
         setInfo({
            ...info,
            time: initTime,
         });
         return;
      }
      setInfo({
         ...info,
         time: info.time--,
      });
      setTimeout(cutDown, 1000);
   };

   const selectAreaCode = code => {
      setInnerAreaCode(code);
      setIsShowArea(false);
      if (onAreaChange && typeof onAreaChange === 'function') {
         onAreaChange(code);
      }
   };

   return (
      <div
         className={['input-box', className].join(' ')}
         style={{ ...style }}
         onMouseMove={e => e.stopPropagation()}
      >
         <div className={['input-box-container', isFocus && 'focus'].join(' ')}>
            {type === 'tel' && (
               <div className="area-code" onMouseEnter={() => setIsShowArea(true)}>
                  <span>+ {innerAreaCode}&nbsp;</span>
                  <span className={['arrow', isShowArea && 'up'].join(' ')}></span>
               </div>
            )}
            {type === 'search' && <img alt="" src={searchPng} />}
            {prefix}
            <input
               ref={inputRef}
               type={info.type}
               className="input"
               placeholder={placeholder}
               value={innerValue}
               onChange={e => valueChange(e)}
               onBlur={() => setIsFocus(false)}
               onFocus={() => setIsFocus(true)}
               maxLength={maxLength}
               name={name}
            />
            {type === 'code' && (
               <span className="send-code" onClick={sendCode}>
                  {info.time === initTime
                     ? I18n.t('发送验证码')
                     : `${I18n.t('重新获取')}(${info.time}s)`}
               </span>
            )}
         </div>

         {type === 'tel' && (
            <div
               className={['select-area-box', isShowArea ? '' : 'hidden'].join(' ')}
               onMouseMove={e => e.stopPropagation()}
            >
               <SelectArea onSelect={selectAreaCode}></SelectArea>
            </div>
         )}
      </div>
   );
};

export default Input;
