const eventBus = {
    evnetList: [],
    // 监听事件
    $on(callbackFun, name) {
        // 同名事件 过滤
        if (this.evnetList.length > 0 && this.evnetList.find((i) => i.name === name)) {
            this.evnetList = this.evnetList.filter((i) => i.name !== name);
        }
        this.evnetList = [...this.evnetList, { name, callbackFun }];
    },
    //触发事件
    $emit(name, data) {
        if (!name) {
            return false;
        }
        this.evnetList.forEach((element) => {
            if (name === element.name) {
                element.callbackFun(data);
            }
        });
    },
    // 取消事件监听
    $remove(name) {
        this.evnetList = [...this.evnetList.filter((i) => i.name !== name)];
    },
};
export default eventBus;
