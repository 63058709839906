import React from 'react';
import I18n from '../../language';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { typeEnum } from '../../services/user';
import bgImg1 from '../../assets/imgs/mealbox/header1@2x.png';
import bgImg2 from '../../assets/imgs/mealbox/header2@2x.png';
import bgImg3 from '../../assets/imgs/mealbox/header3@2x.png';
import btcImg from '../../assets/imgs/mealbox/btc.png';
import { formatNumber } from '../../utils';
function MealBox({ item, ...rest }) {
   const { language } = useSelector(store => store.user);

   const type2Img = {
      classic: bgImg1,
      output: bgImg2,
      guaranteed: bgImg3,
      speeded: bgImg3,
   };
   function getPercent() {
      const total = item.sold + item.stock;

      return ((item.sold / total) * 100).toFixed(0);
   }
   return (
      <div className="mealbox" {...rest}>
         <div className="top">
            <div className="day">
               {item.effect_time} {I18n.t('天')}
            </div>
            <img src={type2Img[item.type]} className="bg" alt="" />
            <img src={btcImg} className="btc" alt="" />
         </div>
         <div className="box-title">{language === 'zh' ? item.name : item.name_en}</div>
         <div className="mx-auto">
            {Number(item.act_price) > 0 ? (
               <span className="tag new">{I18n.t('新客专享')}</span>
            ) : (
               <span className="tag">{I18n.t(typeEnum[item.type]?.text)}</span>
            )}
         </div>
         <div className="cell">
            <div className="cell-item">
               <span>{I18n.t('静态产出率')}</span>
               <span className="sub-text">{(item.static_output_rate * 100).toFixed(2)}%</span>
            </div>
            <div className="cell-item">
               <span>{I18n.t('回本周期')}</span>
               <span className="sub-text">
                  {item.return_time}
                  {I18n.t('天')}
               </span>
            </div>
            <div className="cell-item">
               <span>{I18n.t('总供应')}</span>
               <span className="sub-text">{item.stock + item.sold} TH/S</span>
            </div>
            <div className="cell-item">
               <span>{I18n.t('已售出')}</span>
               <span className="sub-text">{item.sold} TH/S</span>
            </div>
         </div>
         <div className="progress">
            <div
               className="inner"
               style={{
                  width: getPercent() + '%',
                  borderRight:
                     Number(getPercent()) && getPercent() < 3 ? '8px solid #F7A62C' : null,
               }}
            >
               {getPercent() > 10 && <span className="text">{getPercent()}%</span>}
            </div>
            {getPercent() <= 10 && <span className="text1">{getPercent()}%</span>}
         </div>
         <div className="cell">
            <div className="cell-item">
               <span className="sub-text">{I18n.t('价格')}</span>
               <div class="price-wp">
                  <span className="price">{formatNumber(item.price - item.act_price, 4)} USDT</span>
                  {Number(item.act_price) > 0 && <span className="off">{item.price} USDT</span>}
               </div>
            </div>
         </div>
         <div className="tag-wp">
            {item.pool_support ? <div className="item red">{I18n.t('矿池打币')}</div> : null}
            {item.pay_limit === 2 ? <div className="item blue">{I18n.t('限U支付')}</div> : null}
         </div>
         <div className="bottom">
            <Button
               type={Number(item.act_price) > 0 ? 'primary' : ''}
               style={{ width: '100%', height: '42px', marginTop: '34px', fontSize: '16px' }}
            >
               {I18n.t('立即购买')}
            </Button>
         </div>
      </div>
   );
}

export default MealBox;
