import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../store/user';
import I18n from '../../language';
import Header from '../../components/Header';
import { Button } from 'antd';
import Footer from '../Home/footer';
import { useNavigate, useParams } from 'react-router-dom';

export default function Home() {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { language } = useSelector(store => store.user);
   function handleToggle() {
      dispatch(setLanguage(language === 'zh' ? 'en' : 'zh'));
   }

   const headerClickHandler = key => {
      navigate('/home/' + key);
   };

   return (
      <div className="not-found-view">
         <Header onChangeLang={() => handleToggle()} onClick={key => headerClickHandler(key)} />
         <div className="main">
            {/* <img src={img} alt="" /> */}
            <h2>{I18n.t('页面走丢了')}</h2>
            <Button
               type="primary"
               className="mb80"
               style={{ width: '240px' }}
               onClick={() => {
                  navigate('/');
               }}
            >
               {I18n.t('返回首页')}
            </Button>
         </div>
         <Footer />
      </div>
   );
}
