import React from 'react';
import './index.scss';
let importAll = requireContext => requireContext.keys().forEach(requireContext);
try {
   const a = importAll(require.context('../../icons', true, /\.svg$/));
   console.log(a);
} catch (error) {
   console.log(error);
}

// type Props = {
//     name: string,
// }

const Icon = props => {
   return (
      <svg className="icon">
         <use xlinkHref={'#' + props.name} />
      </svg>
   );
};
export default Icon;
