import { Decimal } from 'decimal.js';

/* 防抖 */
export function debounce(fun, wait) {
   let timer;
   return (...args) => {
      if (timer) {
         clearTimeout(timer);
      }
      timer = setTimeout(() => {
         fun(...args);
      }, wait);
   };
}
// 截取地址栏参数
export function queryUrlParams(name) {
   const urlParams = window.location.href.split('?')[1];
   if (!urlParams) return;

   const cateArr = urlParams.split('&');
   for (let i = 0; i < cateArr.length; i++) {
      if (cateArr[i].split('=')[0] === name) {
         return cateArr[i].split('=')[1];
      }
   }
}
/* 异步防抖 */
export function debounceAsync(fun, wait = 500) {
   let timer;
   return (...args) => {
      if (timer) {
         clearTimeout(timer);
      }
      return new Promise(resolve => {
         timer = setTimeout(() => {
            resolve(fun(...args));
         }, wait);
      });
   };
}

/*截取小数 不四舍五入的方式*/
export function unroundNumber(number, length) {
   if (isNaN(number)) return;

   let num = number.toString();
   let index = num.indexOf('.');
   if (index === -1) {
      num = num.substring(0);
   } else {
      num = num.substring(0, length + index + 1);
   }

   return parseFloat(num).toFixed(length);
}
// 精度
export function formatNumber(num, precision) {
   let formatted = num.toFixed(precision);
   return parseFloat(formatted);
}
/* 随机排序 */
export function randomSort(arr) {
   arr.sort((a, b) => (Math.random() > 0.5 ? -1 : 1));
}

/*判断是否是邮箱*/
export function isEmailAddress(email) {
   const regEmail = /^([a-zA-Z0-9_\-\.])+[^.\?]@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
   return regEmail.test(email);
}

/*密码必须包含数字和字母*/
export function checkPassword(str) {
   const reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
   return reg.test(str);
}

// 两数乘法
export function acMul(a, b) {
   if (!a || !b) return 0;
   return Decimal.mul(a, b).toString();
}

// 两数相加
export function acAdd(a, b) {
   if (!a || !b) return 0;
   return Decimal.add(a, b).toString();
}
