import { createSlice } from '@reduxjs/toolkit';

const user = createSlice({
   name: 'user',
   initialState: {
      language: 'zh',
   },
   reducers: {
      setLanguage(state, action) {
         state.language = action.payload;
      },
   },
});

export const { setLanguage } = user.actions;
export const { reducer: userReducer } = user;
