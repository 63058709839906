const en = {
   "立即开始": 'Get Started',
   "返回首页": "Return to home page",
   "点击了解我们": 'Click to know us',
   "扫码下载APP": "Scan code to download",
   注册享双重福利: "Register for dual benefits",
   新用户专属福利: "Exclusive benefits for new users",
   "页面走丢了": "The page is lost",
   Android下载: 'Android Download',
   "想要了解更多矿机信息，请下载 Bitroo APP": "To learn more about mining machines, please download",
   "我知道了": "I know",
   "保本套餐": "Guaranteed Return",
   "服务费": "Service fee",
   "矿池打币": "Mining Pool Paytout ",
   "限U支付": "U Payment Only",
   "服务费缴纳天数": "Service Fee Payment Days",
   "租赁费": "Leasing Fee",
   "矿机租赁": "Minier Leasing",
   "矿机采购": "Minier Purchase",
   "静态回本天数=期初投入/(静态产出数量*币种价格-期中投入)*套餐周期": "Static Payback Period = Initial Investment / (Static Output * BTC Price - Mid-term Investment) * Package Period",
   "套餐": "Package",
   "产品周期": "Product Cycle",
   "产品定位": "Product Positioning",
   "期初投入": "Initial Investment",
   "Bitroo保本套餐": "Bitroo Capital Protection Package",
   "市面其他套餐": "Other Packages on the Market",
   "BTC减半下行行情产出率测算": "BTC Halving Downward Market Output Rate Calculation",
   "套餐周期：120天-240天": "Package Duration: 120 days - 240 days",
   "产出减半": "Halving of Output",
   "BTC价格降至44000~30000USDT": "BTC Price Drops to 44000~30000 USDT",
   "按当前币价计算回本数据并定价，因前期成本已经支付，套餐到期结束，停止产出，用户面临不回本风险。": "Calculation and pricing based on the current coin price, production stops at the end of the package term as upfront costs have already been paid, exposing users to the risk of loss.",
   "到期未回本自动延期，持续兑付，直到累计产出收益超过用户成本，用户既能获得收益又能避免不回本风险。": "Automatically extends if not recovered by the end of the term, continuous payout until accumulated output exceeds user's cost, providing both profits and avoiding the risk of loss.",
   "套餐简介": "Package Introduction",
   "产品优势": "Product Advantages",
   "去下载": "Download",
   "每日预估产出": "Estimated daily output",
   "低能耗": "Low Energy Usage",
   "最实惠": "Cost-effective",
   "高产量": "High Yield",
   "可分期": "Installment",
   "回本快": "Fast ROI",
   "火爆款": "Hot-selling",
   "总价低": "Low Total Price",
   "券已领完": "No Coupon Today",
   "Telegram": "Telegram",
   "Twitter": "Twitter",
   "中亚&北美自营矿场，实力雄厚": "Self-operated mines in Central Asia & North America, strong strength",
   "让普通用户轻松参与比特币挖矿": "Let ordinary users easily participate in Bitcoin mining",
   "一键购买云算力，省去繁琐流程": "Purchase hashrate with one click, eliminating tedious processes",
   "一站式矿机销售、维护与托管服务": "One-stop miner sales, maintenance and hosting services",
   "如何开始Bitroo挖矿": "How to start bitroo mining",
   "免费挖矿30天": "Free mining for 30 days",
   "恭喜您,成为第": "Congratulations on becoming the",
   "位矿工": "miner",
   "服务矩阵": "Service Matrix",
   "提供便捷的比特币算力租赁和转售服务": "Provide convenient bitcoin hashrate rental and resale services",
   "专业比特币矿机销售": "Professional bitcoin mining machine sales",
   "维护和托管服务": "Maintenance and hosting services",
   "专业团队倾力打造，服务至上": "Professional team works hard to create, service first",
   "您有海外账号可直接在App Store下载使用，没有海外账号的请参考": "If you have an overseas account, you can directly download and use them from the App Store. If you don't have an overseas account, please refer to",
   "Bitroo App安装指南": " the Bitroo App installation guide",
   "开始您的挖矿之旅": "Start your mining journey",
   "在售主力机型": "Available Models",
   IOS下载: "IOS Download",
   简体中文: '简体中文',
   注册: 'Register',
   首页: 'Home',
   产品介绍: 'Product Introduction',
   "全产出套餐": "Full Output Mode",
   "回本周期": "Payback Period",
   矿场介绍: 'Mining Plant',
   App下载: 'App Download',
   帮助中心: 'Help Center',
   联系我们: 'Contact Us',
   '简易挖矿 更高收益': 'Easy Mining, Higher Profits.',
   算力平台: 'Hash Rate Platform',
   让用户更轻松地参与数字资产挖矿: 'Easier to participate in Crypto mining',
   '专业的矿机售卖、维护、托管及金融服务':
      'Professional minier sales, maintenance, hosting and financial services',
   算力套餐: 'Hashrate Package',
   矿机套餐: 'Minier Package',
   '加密算法/币种': 'Encryption Algorithms/Coins',
   额定算力: 'Rated Hashrate',
   额定功耗: 'Rated Power Consumption',
   能效比: 'Energy Efficiency Ratio',
   北美基地: 'North American Base',
   矿场面积: 'Mining Plant Area',
   能源: 'Energy Source',
   总容量: 'Total Capacity',
   介绍: 'Introduction',
   中亚基地: 'Central Asia Plant',
   '风能、太阳能': 'Wind, Solar',
   负荷: 'Load',
   水力能源: 'Hydropower Energy',
   矿场介绍1:
      'The North American Plant has abundant grid resources and a robust network infrastructure with advanced cluster processors and high performance energy regulation equipment that has superior computing power and mining efficiency. The team enables these devices to operate in the most efficient manner through professional management, maintenance and operation, resulting in high yield. Our mining plants are built on the concept of sustainability and environmental protection, using green energy to operate the mine, reducing production costs while meeting the global ESG philosophy.',
   矿场介绍2:
      'Focused on mining digital currencies, Central Asia Plant has an advanced environmental temperature control system that enables continuous operation in an energy-efficient manner. The unique location advantage and policy escort create a safe, reliable, fast and convenient, energy-efficient one-stop service center for investors. The team has professional technical and management experience, which enables the equipment to operate in the most efficient way. First-class technical means guarantee the safety of equipment and data.',
   扫描下载: 'Scan To Download',
   常见问题: 'Frequently Asked Questions',
   查看更多: 'View more',
   更多: 'More',
   关于: 'About ',
   关于1: 'Bitroo is a platform focused on making it easier for regular users to participate in bitcoin mining. We provide convenient bitcoin hashrate rental and resale services, professional bitcoin mining machine sales, maintenance and hosting services, and related digital asset financial services. We are committed to making mining easier, maximizing user revenue and serving every user of our platform through professionalism, integrity, innovation and enthusiasm.',
   邮箱: 'Email',
   关注我们: 'Follow Us',
   客服支持: 'Customer Support',
   销售咨询: 'Sales Consulting',
   KOL申请: 'KOL Application',
   矿场合作: 'Mine Cooperation',
   中文推特: 'Chinese Twitter',
   中文群: '中文群',
   '登录密码为8-16位，数字字母符号组合': 'Password is 8-16 digits with numbers and letters',
   "登录密码大于8位": "Password length greater than 8",
   '注册Bitroo 账号，了解更多': 'Register a Bitroo account to learn more',
   请输入邮箱: 'Please enter your email address',
   获取验证码: 'Get verification code',
   s后获取: ' seconds later retrieve',
   请输入验证码: 'Please enter the verification code',
   请输入密码: 'Please enter the password',
   '请输入邀请码（选填）': 'Please enter the invitation code (optional)',
   邮箱不能为空: 'Email cannot be empty',
   手机号不能为空: 'Cell Phone cannot be empty',
   验证码不能为空: 'The verification code cannot be empty',
   密码不能为空: 'Password cannot be empty',
   请输入正确格式的邮箱: 'Please enter the correct email format',
   请输入正确格式的手机号: 'Please enter the correct cell phone format',
   '登录密码为8-16位，数字字母组合':
      'Login password is 8-16 digits, consisting of a combination of numbers，letters and symbol',
   正在注册: 'Waiting',
   注册成功: 'Register successfully',
   发送成功: 'Success',
   什么是算力租赁套餐: 'What is hashrate package',
   如何在Bitroo购买算力租赁套餐: 'How do I buy an hashrate package from Bitroo?',
   算力产品的费用包括哪几部分: 'What are the parts of the cost of the hashrate product',
   常见问题1:
      'Hashrate package is a remote mining model where users purchase an  hashrate package through the platform, i.e. rent the mining power of the actual mining machine, and receive the corresponding mining rewards regularly during the contract period.',
   常见问题2:
      'The purchase process is as follows: register your Bitroo account - enter the page of the hashrate package - select the corresponding hashrate package product - enter the amount of hashrate purchased - click [Buy Now] - submit the order - click [Pay] - complete the security verification - complete the payment. To avoid the failure of purchase, please confirm the payment in time. ',
   常见问题3:
      'The cost of hashrate product is composed of two parts: hashrate  fee and electricity fee. The price of the package when you place an order includes all of the hashrate feesand electricity fee during the period.',
   隐私: 'Privacy Policy',
   协议: 'Terms & Conditions',
   天: 'Days',
   经典套餐: 'Classic Mode',
   加速套餐: 'Speed Mode',
   起购算力规格: 'Minmum Amount',
   静态产出率: 'Static Yield Rate',
   产出率: "Yield Rate",
   算力费单价: 'Hashrate Fee',
   电费单价: 'Electricity Fee',
   打币周期: 'Payout Cycle',
   每日打币: 'Daily',
   生效时间: 'Effective Time',
   总供应: 'Total',
   已售出: 'Sold',
   价格: 'Price',
   立减: 'Off',
   新客专享: 'New Customer Offer',
   '24小时内': 'Within 24 hours',
   套餐详情: 'Package Details',
   套餐说明: 'Package Description',
   无法退款声明: 'Refundable statement',
   不可控风险声明: 'Uncontrollable risk statement',
   使用范围声明: 'Scope of use declaration',
   免责声明1:
      'In classic mode, you will receive all digital currency output throughout the entire cycle.',
   免责声明2:
      'In accelerated cost recovery mode, you can recover costs faster. After recovering costs, you will receive a proportionate portion of the mining output.',
   免责声明3:
      'According to the service agreement, once your purchase is completed, replacement or refund will not be supported. Please make a careful decision before making the purchase.',
   免责声明4:
      'Bitroo will not be responsible for the losses caused by the following uncontrollable risks: unforeseeable, unavoidable or insurmountable objective events, including natural disasters such as floods, volcanic eruptions, earthquakes, landslides, fires, storms and severe weather rated as more than once in a century by relevant government departments, government acts and government orders, urban power grid power supply accidents, and social abnormal events such as war, strikes, unrest, etc.',
   免责声明5:
      'Static output is calculated based on the current computational difficulty and transaction rewards, for reference only. It may change due to fluctuations in market difficulty. Increasing difficulty will result in a decrease in static output, while decreasing difficulty will result in an increase in static output. Bitroo does not make any commitment to BTC output.',
   套餐类型: 'Package Type',
   套餐周期: 'Package Period',
   产出币种: 'Output Currency',
   打币矿池: 'Payout Pool',
   回本后平台分成: 'Platform Sharing Ratio',
   静态回本天数: 'Static Breakeven Days',
   "加速静态产出率": "Static output rate = (Unit static output * Circle days - Hashrate fee unit price * Circle days - Service fee unit price * Payment days) * (1 -Platform Sharing ratio) + Hashrate fee unit price * Circle days + Service fee unit price * Payment days - Service fee unit price * (Circle days - Payment days)) / (Hashrate fee unit price * Package Circle + Service fee unit price * Payment days).",
   保本回本天数: "Static Breakeven Days= Cycle days*(Hash Rate Fee Unit Price*Cycle days+Service Fee Unit Price*First Payment Service Fee Days)/(Unit Static Output*BTC Price*Cycle days-Service Fee Unit Price*(Cycle days-First Payment Service Fee Days))",
   加速回本天数: 'Break-even days = Circle days * (Hashrate fee unit price * Package Circle + Service fee unit price * Payment days) / (Unit static output * Circle days - Hashrate fee unit price * Circle days - Service fee unit price * Payment days) * Sharing ratio + Hashrate fee unit price * Circle days + Service fee unit price * Payment days - Service fee unit price * ( Circle days - Payment days).',
   详情页1:
      'Notice: Static Breakeven Days are for reference only, according to the current mining output data and prices measured after purchase, the measurement is based on product parameters, forecast prices and the current static output rate data, where the output data from F2pool. Due to changes in the difficulty of mining, the output rate may change and the final data may change.',
   详情页2:
      'Static Breakeven Days= (Hash Rate Fee Unit Price + Electricity Fee Unit Price)*Cycle days/(Static Output Amount*Currency price)',
   周期天数: 'Cycle Days',
   回本天数: 'Payback Days',
   立即购买: 'Buy Now',
   算力单位: 'Computing power unit',
   算力费: 'Computing fee',
   套餐周期desc:
      'Corresponding to the validity period of the computing power product, it will be calculated since the computing power is online, and the user will get the mining output of the purchased computing power within the number of days.',
   算力单位desc:
      'Bitroo computing power rental products use the "Th/s" of the Bitcoin SHA256 algorithm as a single computing power unit.',
   算力费desc:
      'The computing power lease, mining machine breakage, and labor maintenance fees required to pay for computing power products in this period.',
   电费desc:
      'The electricity fee and line loss electricity fee that the computing power product needs to pay in this period.',
   静态产出率desc1:
      'Static Yield Rate = Static Output/(Electricity Fee Cost +Hashrate Fee Cost) * 100%',
   静态产出率desc2:
      'Static output rate = (static output - post-paid service fee)/(service fee input + computing fee input)*100%',
   // 'Static Yield Rate = (Package Period -Static Breakeven Days) Order Quantity Static Output/Specification Unit/Day * Share Ratio/(Electricity Fee Cost +Hashrate Fee Cost) * 100%',
   电费: 'Electricity',
   算力费投入: 'Hash Rate Fee Cost',
   "服务费投入": "Service Fee cost",
   电费投入: 'Electricity Fee Cost',
   静态产出: 'Static Output',
   挖矿净收益: 'Net Mining Revenue',
   // 新的
   热门国家地区: 'Popular countries and regions',
   所有国家地区: 'All countries and regions',
   搜索地区或区号: 'Search for region or area code',
   发送验证码: 'Send Code',
   重新获取: 'Try Again',
   邮箱注册: 'Email',
   手机号注册: 'Cell Phone',
   请输入手机号: 'Please enter phone number',
   注册表明您已同意: 'By clicking Sign up, you agree to our',
   用户协议: 'Terms of Services',
   已有账号: 'Already have an account',
   登录: 'Login',
}
export default en;
