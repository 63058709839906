import React, { forwardRef } from 'react';
import emailImg from '../../assets/imgs/contact/email.png';
import followImg from '../../assets/imgs/contact/follow.png';
import teleImg from '../../assets/imgs/contact/tele.png';
import bgImg from '../../assets/imgs/contact/bg.png';
import luckImg from '../../assets/imgs/contact/luck.png';
import I18n from '../../language';

const Contact = forwardRef((props, ref) => {
   const items = [
      {
         img: emailImg,
         label: '邮箱',
         list: [
            {
               text: '客服支持',
               linktext: 'support@bitroo.com',
               href: 'mailto:support@bitroo.com',
            },
            {
               text: '销售咨询',
               linktext: 'sales@bitroo.com',
               href: 'mailto:sales@bitroo.com',
            },
            {
               text: 'KOL申请',
               linktext: 'kol@bitroo.com',
               href: 'mailto:kol@bitroo.com',
            },
         ],
      },
      {
         img: teleImg,
         label: 'Telegram',
         list: [
            {
               text: '客服支持',
               linktext: '@bitroosupport',
               href: 'https://t.me/bitroosupport',
            },
            {
               text: '销售咨询',
               linktext: '@bitroosales',
               href: 'https://t.me/bitroosales',
            },
            {
               text: 'KOL申请',
               linktext: '@bitrookol',
               href: 'https://t.me/bitrookol',
            },
            {
               text: I18n.t('矿场合作'),
               linktext: '@bitroosbusiness',
               href: 'https://t.me/bitroosbusiness',
            },
         ],
      },
      {
         img: followImg,
         label: '关注我们',
         list: [
            {
               text: 'Twitter',
               linktext: '@BitrooOfficial',
               href: 'https://twitter.com/BitrooOfficial',
            },
            {
               text: '中文推特',
               linktext: '@BitrooCN',
               href: 'https://twitter.com/BitrooCN',
            },
            {
               text: '',
               linktext: 'Telegram Group',
               href: 'https://t.me/BitrooEN',
            },
            {
               text: '',
               linktext: `Telegram 中文群`,
               href: 'https://t.me/BitrooCN',
            },
         ],
      },
   ];
   return (
      <div className="contact" ref={ref}>
         <img src={bgImg} alt="" className="bg" />
         <div className="content">
            <h2>{I18n.t('联系我们')}</h2>
            <img src={luckImg} alt="" className="luck" />
            <div className="link">
               {items.map(item => {
                  return (
                     <div className="item" key={item.img}>
                        <div className="top">
                           <img src={item.img} alt="" /> <span>{I18n.t(item.label)}</span>
                        </div>
                        {item.list.map(l => {
                           return (
                              <div className="cell" key={l.href}>
                                 <span>{l.text ? I18n.t(l.text) : ''}</span>
                                 <a
                                    href={l.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ marginLeft: l.text ? '10px' : '0' }}
                                 >
                                    {l.linktext}
                                 </a>
                              </div>
                           );
                        })}
                     </div>
                  );
               })}
            </div>
         </div>
      </div>
   );
});

export default Contact;
