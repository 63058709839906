import { useNavigate } from 'react-router-dom';
import SvgIcon from '../../components/SvgIcon';
import I18n from '../../language';
export default function FinishModal({ show, mineNumber }) {
   const navigate = useNavigate();
   const finishHandler = () => {
      navigate('/home/download');
   };
   return show ? (
      <div className={'finish-modal'}>
         <div className="center">
            <div className="title">{I18n.t('注册成功')}</div>
            <div className={I18n.locale + ' msg-wp'}>
               <p className={I18n.locale}>
                  {I18n.t('恭喜您,成为第')} <span>{mineNumber}</span> {I18n.t('位矿工')}
               </p>
               <span>{I18n.t('免费挖矿30天')}</span>
            </div>
            {/* <img src={welfareMsg.content.length > 0 ? img2 : img1} alt="" /> */}
            <div className="btn" onClick={finishHandler}>
               <SvgIcon name="phone"></SvgIcon>
               {I18n.t('扫码下载APP')}
            </div>
         </div>
      </div>
   ) : null;
}
