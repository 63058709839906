import React, { forwardRef, useEffect } from 'react';
import { HashRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Register from './Register';
import Home from './Home';
import Policy from './Policy';
import Details from './Details';
import NotFound from './NotFound';
const App = forwardRef((props, ref) => {
   const nav = useLocation();
   const path2title = {
      '/': 'Home',
      '/home': 'Home',
      '/register': 'Register',
      '/policy': 'Policy',
      '/details': 'Details',
      '/download': 'Download',
      '/pages/Login/register': 'Register',
   };
   useEffect(() => {
      document.title =
         'Cloud Mining & BTC, Bitcoin Hashrate Platform | WEB | ' + path2title[nav.pathname];
   });
   return (
      <Routes>
         <Route path="/" meta="xxx" element={<Home />} />
         <Route path="/home/:key" element={<Home />} />
         <Route path="/register" element={<Register />} />
         <Route path="/policy/:type" element={<Policy />} />
         <Route path="/details/:id" element={<Details />} />
         {/* pages/Login/register */}
         {/* 重定向 */}
         <Route exact path="/download" element={<Navigate to="/home/download" />} />
         <Route
            path="/pages/Main/download"
            element={<Navigate to={{ pathname: '/home/download', search: '?x=222' }} />}
         />
         <Route exact path="/pages/Login/register" element={<Register />} />
         <Route exact path="/register" element={<Register />} />
         <Route path="*" element={<NotFound />} />
         {/* <Route element={<Navigate to="/404" />} />
            <Route path="/404" element={<NotFound />} /> */}
      </Routes>
   );
});

export default App;
