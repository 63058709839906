import React, { useRef, useState, useEffect } from 'react';
import I18n from '../../language';
import img2 from '../../assets/imgs/header/logo_white.png';

export default function Footer() {
   return (
      <div className="footer">
         <div className="footer-item">
            <img src={img2} alt="" />
            <div className="policy">
               <span
                  className="terms"
                  onClick={() => {
                     window.open(window.location.origin + '/#/policy/policy');
                  }}
               >
                  {I18n.t('隐私')}
               </span>
               <span className="line">|</span>
               <span
                  className="terms"
                  onClick={() => {
                     window.open(window.location.origin + '/#/policy/user');
                  }}
               >
                  {I18n.t('协议')}
               </span>
            </div>
         </div>
         <span className="msg">{`Copyright ${new Date().getFullYear()} Bitroo Technology Co., Limited.`}</span>
      </div>
   );
}
