const zh = {
   立即开始: '立即开始',
   注册享双重福利: "注册享双重福利",
   新用户专属福利: "新用户专属福利",
   点击了解我们: "点击了解我们",
   "扫码下载APP": "扫码下载APP",
   "返回首页": "返回首页",
   "券已领完": "券已领完",
   "套餐简介": "套餐简介",
   "产品优势": "产品优势",
   "保本套餐": "保本套餐",
   "页面走丢了": "页面走丢了",
   "服务费": "服务费",
   "租赁费": "租赁费",
   "矿机租赁": "矿机租赁",
   "矿机采购": "矿机采购",
   "产品周期": "产品周期",
   "产品定位": "产品定位",
   "矿池打币": "矿池打币",
   "限U支付": "限U支付",
   "服务费缴纳天数": "服务费缴纳天数",
   "期初投入": "期初投入",
   "静态回本天数=期初投入/(静态产出数量*币种价格-期中投入)*套餐周期": "静态回本天数=期初投入/(静态产出数量*币种价格-期中投入)*套餐周期",
   "套餐": "套餐",
   "产出率": "产出率",
   "Bitroo保本套餐": "Bitroo保本套餐",
   "市面其他套餐": "市面其他套餐",
   "BTC减半下行行情产出率测算": "BTC减半下行行情产出率测算",
   "套餐周期：120天-240天": "套餐周期：120天-240天",
   "产出减半": "产出减半",
   "BTC价格降至44000~30000USDT": "BTC价格降至44000~30000USDT",
   "按当前币价计算回本数据并定价，因前期成本已经支付，套餐到期结束，停止产出，用户面临不回本风险。": "按当前币价计算回本数据并定价，因前期成本已经支付，套餐到期结束，停止产出，用户面临不回本风险。",
   "到期未回本自动延期，持续兑付，直到累计产出收益超过用户成本，用户既能获得收益又能避免不回本风险。": "到期未回本自动延期，持续兑付，直到累计产出收益超过用户成本，用户既能获得收益又能避免不回本风险。",
   "想要了解更多矿机信息，请下载 Bitroo APP": "想要了解更多矿机信息，请下载 Bitroo APP",
   "我知道了": "我知道了",
   "去下载": "去下载",
   "每日预估产出": "每日预估产出",
   "低能耗": "低能耗",
   "可分期": "可分期",
   "最实惠": "最实惠",
   "高产量": "高产量",
   "回本快": "回本快",
   "火爆款": "火爆款",
   "总价低": "总价低",
   "中亚&北美自营矿场，实力雄厚": "中亚&北美自营矿场，实力雄厚",
   "专业团队倾力打造，服务至上": "专业团队倾力打造，服务至上",
   "让普通用户轻松参与比特币挖矿": "让普通用户轻松参与比特币挖矿",
   "一键购买云算力，省去繁琐流程": "一键购买云算力，省去繁琐流程",
   "一站式矿机销售、维护与托管服务": "一站式矿机销售、维护与托管服务",
   "提供便捷的比特币算力租赁和转售服务": "提供便捷的比特币算力租赁和转售服务",
   "免费挖矿30天": "免费挖矿30天",
   "恭喜您,成为第": "恭喜您,成为第",
   "位矿工": "位矿工",
   "如何开始Bitroo挖矿": "如何开始Bitroo挖矿",
   "专业比特币矿机销售": "专业比特币矿机销售",
   "维护和托管服务": "维护和托管服务",
   "在售主力机型": "在售主力机型",
   "Telegram": "Telegram",
   "Twitter": "Twitter",
   "服务矩阵": "服务矩阵",
   Android下载: 'Android下载',
   "开始您的挖矿之旅": "开始您的挖矿之旅",
   "您有海外账号可直接在App Store下载使用，没有海外账号的请参考": "您有海外账号可直接在App Store下载使用，没有海外账号的请参考",
   "Bitroo App安装指南": "Bitroo App安装指南",
   IOS下载: "IOS下载",
   回本周期: "回本周期",
   简体中文: '简体中文',
   "全产出套餐": "全产出套餐",
   注册: '注册',
   首页: '首页',
   产品介绍: '产品介绍',
   矿场介绍: '矿场介绍',
   App下载: 'App下载',
   帮助中心: '帮助中心',
   联系我们: '联系我们',
   '简易挖矿 更高收益': '简易挖矿 更高收益',
   算力平台: '算力平台',
   让用户更轻松地参与数字资产挖矿: '让用户更轻松地参与数字资产挖矿',
   '专业的矿机售卖、维护、托管及金融服务': '专业的矿机售卖、维护、托管及金融服务',
   算力套餐: '算力套餐',
   矿机套餐: '矿机套餐',
   '加密算法/币种': '加密算法/币种',
   额定算力: '额定算力',
   额定功耗: '额定功耗',
   能效比: '能效比',
   北美基地: '北美基地',
   矿场面积: '矿场面积',
   能源: '能源',
   总容量: '总容量',
   介绍: '介绍',
   中亚基地: '中亚基地',
   '风能、太阳能': '风能、太阳能',
   负荷: '负荷',
   水力能源: '水力能源',
   矿场介绍1:
      '北美基地拥有丰富的电网资源和强大的网络基础设施，拥有先进的集群处理器和高性能能源调控设备，这些设备具有卓越的计算能力和挖掘效率。团队通过专业的管理、维护和运作，使这些设备能够以最高效的方式运行，从而获得高收益。矿场建立在可持续性和环保理念的基础之上，使用绿色能源来运作矿场，降低生产成本的同时，也满足全球生态环保的理念。',
   矿场介绍2:
      '中亚基地专注于挖掘数字货币，拥有先进的环境温控系统，能够以高效节能的方式持续运营。得天独厚的地理位置优势与政策护航，为投资者创造出一个安全可靠、快捷便利、高效节能的一站式服务中心。团队拥有专业的技术和管理经验，使得设备能够以最高效的方式运作。一流的技术手段，保障了设备和数据的安全。',
   扫描下载: '扫描下载',
   常见问题: '常见问题',
   查看更多: '查看更多',
   更多: '更多',
   关于: '关于',
   关于1: 'Bitroo 是一个专注于让普通用户更轻松地参与比特币挖矿的平台。我们提供便捷的比特币算力租赁和转售服务，专业的比特币矿机售卖、维护和托管服务，以及相关数字资产金融服务。我们致力于让挖矿更简单，让用户收益最大化，通过专业、诚信、创新、热情的态度服务好平台的每一位用户。',
   邮箱: '邮箱',
   关注我们: '关注我们',
   客服支持: '客服支持',
   销售咨询: '销售咨询',
   KOL申请: 'KOL申请',
   矿场合作: '矿场合作',
   中文推特: '中文推特',
   中文群: '中文群',
   '注册Bitroo 账号，了解更多': '注册Bitroo 账号，了解更多',
   获取验证码: '获取验证码',
   s后获取: 's后获取',
   请输入验证码: '请输入验证码',
   请输入密码: '请输入密码',
   '请输入邀请码（选填）': '请输入邀请码（选填）',
   邮箱不能为空: '邮箱不能为空',
   手机号不能为空: '手机号不能为空',
   验证码不能为空: '验证码不能为空',
   密码不能为空: '密码不能为空',
   请输入邮箱: '请输入邮箱',
   请输入正确格式的邮箱: '请输入正确格式的邮箱',
   请输入正确格式的手机号: '请输入正确格式的手机号',
   '登录密码为8-16位，数字字母符号组合': '登录密码为8-16位，数字字母符号组合',
   '登录密码大于8位': '登录密码大于8位',
   正在注册: '正在注册',
   注册成功: '注册成功',
   发送成功: '发送成功',
   什么是算力租赁套餐: '什么是算力租赁套餐',
   如何在Bitroo购买算力租赁套餐: '如何在Bitroo购买算力租赁套餐',
   算力产品的费用包括哪几部分: ' 算力产品的费用包括哪几部分',
   常见问题1:
      '算力租赁是一种远程挖矿模式，用户通过平台购买算力租赁套餐，即租赁实际矿机的算力挖矿，并在合约周期内定时获得相应的挖矿奖励。',
   常见问题2:
      '算力租赁购买流程如下：注册 Bitroo 账号-进入算力租赁页面-选择对应算力租赁产品-输入购买的算力数量-点击【立即购买】-提交订单-点击【支付】-完成安全验证-完成支付。为避免抢购失败，请及时确认支付。',
   常见问题3:
      '算力产品费用由算力服务费和电费两部分构成。下单时支付套餐的价格包括周期内全部的算力服务费及电费。',
   隐私: '隐私政策',
   协议: '服务协议',
   天: '天',
   经典套餐: '经典套餐',
   加速套餐: '加速套餐',
   起购算力规格: '起购算力规格',
   静态产出率: '静态产出率',
   算力费单价: '算力费单价',
   电费单价: '电费单价',
   打币周期: '打币周期',
   每日打币: '每日打币',
   生效时间: '生效时间',
   总供应: '总供应',
   已售出: '已售出',
   价格: '价格',
   立减: '立减',
   新客专享: '新客专享',
   '24小时内': '24小时内',
   套餐详情: '套餐详情',
   套餐说明: '套餐说明',
   无法退款声明: '无法退款声明',
   不可控风险声明: '不可控风险声明',
   使用范围声明: '使用范围声明',
   免责声明1: '在经典模式下，您将获得整个周期全部数字货币产出。',
   免责声明2: '在加速回本模式下，您可以更快回收成本，收回成本后，您将按比例获得挖矿产出部分。',
   免责声明3: '按照服务协议规定，一旦您购买完成，将不支持更换或退款，请于购买前慎重决定。',
   免责声明4:
      'Bitroo不对以下不可控风险所造成的损失负责：不能预见、不能避免或不能克服的客观事件，包括自然灾害如洪水、火山爆发、地震、山崩、火灾、被相关政府部门评定为百年不遇级别以上的风暴和恶劣气候等，政府行为和政府指令，城市级别的电网供电事故，以及社会异常事件如战争、罢工、动乱等。',
   免责声明5:
      '静态产出是根据当前算力难度及交易奖励静态推算，仅供参考，会因市场难度波动产生变化，难度提高静态产出会减少，难度降低静态产出会增多，Bitroo对BTC产出不作承诺。',
   套餐类型: '套餐类型',
   套餐周期: '套餐周期',
   产出币种: '产出币种',
   打币矿池: '打币矿池',
   回本后平台分成: '回本后平台分成',
   静态回本天数: '静态回本天数',
   详情页1:
      '提示：静态回本天数为，按当前挖矿产出数据及价格测算的购买后回本天数，测算基于产品参数、预测价格和当前静态产出率数据，其中产出数据来自F2pool，仅供参考。由于挖矿难度的变化，产出率可能发生变化，最终数据可能变化。',
   详情页2: '静态回本天数= (算力费单价+电费单价)*周期天数/(静态产出数量*币种价格)',
   保本回本天数: "静态回本天数= 周期天数*(算力单价*周期天数+服务费单价*首笔支付服务费天数)/(单位静态产出*BTC价格*周期天数-服务费单价*(周期天数-首笔支付服务费天数))",
   加速回本天数: '静态回本天数=周期天数*(算力费单价*套餐周期+服务费单价*支付天数)/(单位静态产出*周期天数-算力费单价*周期天数-服务费单价*支付天数)*分成比例+算力费单价*周期天数+服务费单价*支付天数-服务费单价*(套餐周期-支付天数))',
   "加速静态产出率": "  静态产出率=（单位静态产出*周期天数-算力费单价*周期天数-服务费单价*支付天数)*分成比例+算力费单价*周期天数+服务费单价*支付天数-服务费单价*(套餐周期-支付天数))/(算力费单价*套餐周期+服务费单价*支付天数）",
   回本天数: '回本天数',
   周期天数: '周期天数',
   立即购买: '立即购买',
   算力单位: '算力单位',
   算力费: '算力费',
   套餐周期desc:
      '对应算力产品的有效期，自算力上线起进行计算，用户将获得该天数内所购算力的挖矿产出。',
   算力单位desc: 'Bitroo算力租赁产品使用比特币SHA256算法的“Th/s”作为单个算力单位。',
   算力费desc: '该期算力产品所需支付的算力租赁、矿机折损、人工维护费用。',
   电费desc: '该期算力产品所需支付的电费、线损电力费用。',
   静态产出率desc1: '静态产出率 = 静态产出 /（电费投入+算力费投入） * 100%',
   静态产出率desc2:
      '静态产出率 = (单位静态产出率 - 服务费单价*(周期天数-首笔支付服务费天数))/(服务费天数*首笔支付服务费天数 +算力单价*周期天数) * 100%',
   电费: '电费',
   算力费投入: '算力费投入',
   "服务费投入": "服务费投入",
   电费投入: '电费投入',
   静态产出: '静态产出',
   挖矿净收益: '挖矿净收益',
   // 新的
   热门国家地区: '热门国家地区',
   所有国家地区: '所有国家地区',
   搜索地区或区号: '搜索地区或区号',
   发送验证码: '发送验证码',
   重新获取: '重新获取',
   邮箱注册: '邮箱注册',
   手机号注册: '手机号注册',
   请输入手机号: '请输入手机号',
   注册表明您已同意: '注册表明您已同意',
   用户协议: '用户协议',
   已有账号: '已有账号',
   登录: '登录',
}
export default zh;
