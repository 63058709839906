import Platform from './platform';
import Product from './product';
import Mine from './mine';
import Download from './download';
import Help from './help';
import Contact from './contact';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../store/user';
import Header from '../../components/Header';
import Footer from './footer';

export default function Home() {
   const dispatch = useDispatch();
   const { language } = useSelector(store => store.user);
   function handleToggle() {
      dispatch(setLanguage(language === 'zh' ? 'en' : 'zh'));
   }

   const headerClickHandler = key => {
      const dom = document.querySelector(`.${key}`);
      dom && dom.scrollIntoView(true);
   };

   return (
      <div className="home">
         <Header onChangeLang={() => handleToggle()} onClick={key => headerClickHandler(key)} />
         <div className="main">
            <Platform className="platform" />
            <Product className="Product" />
            <Mine className="Mine" />
            <Download className="Download" />
            <Help className="Help" />
            <Contact className="Contact" />
            <Footer />
            {/* <div className="footer">
               <div className="footer-item">
                  <img src={img2} alt="" />
                  <div className="policy">
                     <span
                        className="terms"
                        onClick={() => {
                           window.open(window.location.origin + '/#/policy/policy');
                        }}
                     >
                        {I18n.t('隐私')}
                     </span>
                     <span className="line">|</span>
                     <span
                        className="terms"
                        onClick={() => {
                           window.open(window.location.origin + '/#/policy/user');
                        }}
                     >
                        {I18n.t('协议')}
                     </span>
                  </div>
               </div>
               <span className="msg">{`Copyright ${new Date().getFullYear()} Bitroo Technology Co., Limited.`}</span>
            </div> */}
         </div>
      </div>
   );
}
