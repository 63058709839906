import React from 'react';
export default function Bar({
   bgc = '#EB840B',
   sliderBgc = '#F7B52C',
   percent = 0,
   height = 150,
   width = 24,
   style = {},
   className,
}) {
   return (
      <div
         style={{
            backgroundColor: bgc,
            height: height + 'px',
            width: width + 'px',
            borderRadius: `${width / 4}px`,
            ...style,
         }}
         className={className}
      >
         <div
            style={{
               borderRadius: `${width / 4}px`,
               backgroundColor: sliderBgc,
               width: width + 'px',
               height: `${(percent * 100).toFixed(0)}%`,
            }}
         ></div>
      </div>
   );
}
