import axios from 'axios';
// import { BASE_URL } from '../services';
import { message } from 'antd';
import eventBus from './eventBus';
import I18n from '../language'

let props;
let msgFlag = false;

eventBus.$on(propsObj => {
   props = propsObj;
}, 'axiosMonitor');

const instance = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
   timeout: 30000,
});

//请求拦截处理
instance.interceptors.request.use(
   config => {
      const storageUser = localStorage.getItem('user');
      if (storageUser) config.headers['Authorization'] = storageUser.token;
      config.headers['X-Lang'] = I18n.locale === 'zh' ? 'zh_CN' : 'en_US';
      return config;
   },
   error => {
      return Promise.reject(error);
   }
);

//返回拦截处理
instance.interceptors.response.use(
   response => {
      // 2xx 范围内的状态码都会触发该函数
      return response;
   },
   error => {
      console.log(error)
      const config = error.config
      //  isHideError 不提示服务器错误
      if (config?.method === 'get' && !config?.params?.isHideError) {
         dealCode(error.response?.status);
      } else if (error.config?.method === 'post' && !config?.data?.isHideError) {
         dealCode(error.response?.status);
      }
      // 超出 2xx 范围的状态码都会触发该函数
      return Promise.reject(error);
   }
);

function serverError() {
   if (msgFlag === false) {
      message.warning('服务器发生错误！');
      msgFlag = true;
      setTimeout(() => {
         msgFlag = false;
      }, 6000 * 2);
   }
}

/*处理异常Code*/
function dealCode(code) {
   switch (code) {
      case 404:
         props && props.history.push('/404');
         break;
      default:
         serverError();
         break;
   }
}

export default instance;
