import React, { useRef, useState, useEffect } from 'react';
import PrivacyPolicy from './privacyPolicy';
import UserAgreement from './userAgreement';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../store/user';
import Header from '../../components/Header';
import img2 from '../../assets/imgs/header/logo_white.png';

export default function Home() {
   const navigate = useNavigate();

   const params = useParams();
   const dispatch = useDispatch();
   const { language } = useSelector(store => store.user);

   function handleToggle() {
      dispatch(setLanguage(language === 'zh' ? 'en' : 'zh'));
   }
   const headerClickHandler = key => {
      navigate('/?position=' + key);
      // const dom = document.querySelector(`.${key}`);
      // dom && dom.scrollIntoView(true);
   };
   return (
      <div className="home">
         <Header onChangeLang={() => handleToggle()} onClick={key => headerClickHandler(key)} />
         <div className="main">
            <div style={{ width: '70%', margin: '30px auto' }}>
               {params.type === 'policy' ? <PrivacyPolicy /> : <UserAgreement />}
            </div>
            <div className="footer">
               <div className="footer-item">
                  <img src={img2} alt="" />
                  <span>Copyright 2023 Bitroo All Rights Reserved.</span>
               </div>
            </div>
         </div>
      </div>
   );
}
