import React from 'react';
import I18n from '../../language';
import { useSelector } from 'react-redux';
import { acMul, acAdd, formatNumber } from '../../utils';
import Bar from '../../components/Bar';

export default function Static({ item }) {
   const { language } = useSelector(store => store.user);
   const paidFess = item.type === 'speeded' ? 0 : item.not_paid_fees * 1;
   function calcOutput() {
      const remain = formatNumber(
         Number(item.income) - acAdd(item.hashrate_fees, item.ele_fees * 1 + paidFess),
         4
      );
      return remain;
   }

   function calcPercent() {
      if (!item.hashrate_fees) return 0;
      return item.hashrate_fees / acAdd(item.hashrate_fees, item.ele_fees * 1 + paidFess);
   }
   function getModeDesByType(type) {
      let str = '';
      if (type === 'classic') {
         str = (
            <div>
               <span>{I18n.t('经典套餐')}:</span>
               <span>{I18n.t('静态产出率desc1')}</span>
            </div>
         );
      }
      if (type === 'output') {
         str = (
            <div>
               <span>{I18n.t('全产出套餐')}:</span>
               <span>{I18n.t('静态产出率desc2')}</span>
            </div>
         );
      }
      if (type === 'guaranteed') {
         str = (
            <div>
               <span>{I18n.t('保本套餐')}:</span>
               <span>{I18n.t('静态产出率desc2')}</span>
            </div>
         );
      }
      if (type === 'speeded') {
         str = (
            <div>
               <span>{I18n.t('加速套餐')}:</span>
               <span>{I18n.t('加速静态产出率')}</span>
            </div>
         );
      }
      return str;
   }
   return (
      <div className="section-static">
         <div className="box-cell section-static-item">
            <div className="caption">
               <span>{I18n.t('静态产出率')}</span>
               <span>{acMul(item.static_output_rate, 100)}%</span>
            </div>
            <div className="static-item-side">
               <div className="left">
                  <div style={{ width: language === 'zh' ? '88px' : '140px' }}>
                     <span></span>
                     <span>{I18n.t('算力费投入')}</span>
                  </div>
                  <div>
                     <span style={{ backgroundColor: '#EB840B' }}></span>
                     <span>{I18n.t('服务费投入')}</span>
                  </div>
                  <div>
                     <span style={{ backgroundColor: '#52AF99' }}></span>
                     <span>{I18n.t('静态产出')}</span>
                  </div>
               </div>
               <div className="mid" style={{ marginLeft: language === 'zh' ? '-10px' : '-30px' }}>
                  <div className="mid-left">
                     <span>{I18n.t('挖矿净收益')}</span>
                     <p>{calcOutput()} USDT</p>
                  </div>
                  <div
                     style={{
                        marginLeft: '20px',
                        marginRight: '30px',
                     }}
                  >
                     <p>
                        {/* item. */}
                        {formatNumber(
                           Number(item.hashrate_fees) + Number(item.ele_fees) + Number(paidFess),
                           4
                        )}{' '}
                        USDT
                     </p>
                     <Bar percent={calcPercent()} height={language === 'zh' ? 150 : 250} />
                  </div>
                  <div>
                     <div>
                        <span>{item.hashrate} BTC</span>
                        <p>≈ {item.income} USDT</p>
                     </div>
                     <Bar bgc="#52AF99" height={language === 'zh' ? 180 : 280} />
                  </div>
               </div>
               <div className="right">
                  <div>
                     <span>{I18n.t('套餐周期')}:</span>
                     <span>{I18n.t('套餐周期desc')}</span>
                  </div>
                  <div>
                     <span>{I18n.t('算力单位')}:</span>
                     <span>{I18n.t('算力单位desc')}</span>
                  </div>
                  <div>
                     <span>{I18n.t('算力费')}:</span>
                     <span>{I18n.t('算力费desc')}</span>
                  </div>
                  <div>
                     <span>{I18n.t('电费')}:</span>
                     <span>{I18n.t('电费desc')}</span>
                  </div>
                  {getModeDesByType(item.type)}
               </div>
            </div>
         </div>
      </div>
   );
}
