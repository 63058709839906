import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../store/user';
import I18n from '../../language';
import { getCloudMinerDetail, getStaticOutput } from '../../services/user';
import Introduce from './introduce';
import Details from './details';
import Income from './income';
import Head from './head';
import HeaderMenu from '../../components/Header';
import BreakEvent from './breakEventDes';
import Static from './static';
import ballImg from '../../assets/imgs/detail/ball.png';
import luckImg from '../../assets/imgs/detail/luck.png';
import img2 from '../../assets/imgs/header/logo_white.png';
import SpeededDes from './speededDes';
import { store } from '../../store';

export default function MealDetails() {
   const [item, setItem] = useState({});
   const [output, setOutput] = useState({});

   const params = useParams();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { language } = useSelector(store => store.user);

   function onChange(key) {
      navigate('/home/' + key);
   }
   let currentLang = null;
   store.subscribe(() => {
      const { language } = store.getState().user;
      if (currentLang && language !== currentLang) {
         getData();
      }
   });
   function handleToggle() {
      dispatch(setLanguage(language === 'zh' ? 'en' : 'zh'));
   }

   function getData(id = params.id) {
      currentLang = I18n.locale;
      getCloudMinerDetail({ id }).then(res => {
         if (res.data.code === 0) {
            setItem(res.data.data);
         }
      });
   }

   useEffect(() => {
      if (params.id) {
         getData(params.id);
      }
   }, []);

   useEffect(() => {
      if (JSON.stringify(item) !== '{}') {
         getStaticOutput({ id: item.id, sale_nums: item.min_orders })
            .then(res => {
               if (res.data.code === 0) {
                  setOutput(res.data.data);
               }
            })
            .catch(e => {});
      }
   }, [item]);

   return (
      <div className="home">
         <HeaderMenu onChangeLang={() => handleToggle()} onClick={key => onChange(key)} />
         <div className="main">
            <div className="details-content view">
               <h2>{I18n.t('套餐详情')}</h2>
               <div className="section">
                  <img src={luckImg} alt="" className="luck" />
                  <img src={ballImg} alt="" className="ball" />
                  <Head item={{ ...item, output_rate: output.static_output_rate }} />
                  {item.type === 'guaranteed' ? <BreakEvent item={item} /> : null}
                  {item.type == 'speeded' ? <SpeededDes item={item} /> : null}
                  <Details item={item} />
                  <Static item={{ ...item, ...output }} />
                  <Income item={item} />
                  <Introduce />
               </div>
            </div>

            <div className="footer">
               <div className="footer-item">
                  <img src={img2} alt="" />
                  <div className="policy">
                     <span
                        className="terms"
                        onClick={() => {
                           window.open(window.location.origin + '/#/policy/policy');
                        }}
                     >
                        {I18n.t('隐私')}
                     </span>
                     <span className="line">|</span>
                     <span
                        className="terms"
                        onClick={() => {
                           window.open(window.location.origin + '/#/policy/user');
                        }}
                     >
                        {I18n.t('协议')}
                     </span>
                  </div>
               </div>
               <span className="msg">{`Copyright ${new Date().getFullYear()} Bitroo Technology Co., Limited.`}</span>
            </div>
         </div>
      </div>
   );
}
