import { BrowserRouter as Router, HashRouter } from 'react-router-dom';
import App from './app';
function Page() {
   return (
      <HashRouter>
         <App />
      </HashRouter>
   );
}
export default Page;
