// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigPRD = {
  apiKey: "AIzaSyD70Hz3HYhNPuesfdMPCdpFZMvESk-IK-0",
  authDomain: "bitroo-f9967.firebaseapp.com",
  projectId: "bitroo-f9967",
  storageBucket: "bitroo-f9967.appspot.com",
  messagingSenderId: "265290532228",
  appId: "1:265290532228:web:62210d69450ce1f2b7c9d1",
  measurementId: "G-S9VBQT3F5Y",
};
const firebaseConfigDEV = {
  apiKey: "AIzaSyDo2OXCiYeKO6R4UL1HLGd_-tiURBD4inM",
  authDomain: "bitroo-test.firebaseapp.com",
  projectId: "bitroo-test",
  storageBucket: "bitroo-test.appspot.com",
  messagingSenderId: "377342066927",
  appId: "1:377342066927:web:487bbb8a8716bff5505b1a",
  measurementId: "G-XP75QXZVMC",
};
// Initialize Firebase
const app = initializeApp(
  process.env.NODE_ENV === 'development' ? firebaseConfigDEV : firebaseConfigPRD
);
const analytics = getAnalytics(app);
// const Track = () => {
//   logEvent(analytics, "notification_received")
// }
export { analytics, logEvent, setUserProperties };
