import React from 'react';
import { useSelector } from 'react-redux';

function UserAgreement() {
   const { language } = useSelector(store => store.user);

   if (language === 'zh') {
      document.title = '用戶协议';
   } else {
      document.title = 'User Agreement';
   }

   return (
      <div>
         {language === 'zh' && (
            <p style={{ margin: 10, fontSize: 14, lineHeight: '26px' }}>
               <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16 }}>服务协议</div>
               <br />
               《Bitroo服务协议》（“本协议”）是Bitroo（“Bitroo”或“我们”或“公司”）和使用Bitroo提供的服务和/或Bitroo的官网[Bitroo.com]（“Bitroo网站”）的用户（“用户”或“您”）之间的协议。本协议描述了Bitroo和使用者的相关权利和责任。您使用Bitroo提供的服务和/或Bitroo网站，即表示您同意本协议规定的全部条款。如果您不同意，请勿使用Bitroo提供的服务和Bitroo网站。如您购买了Bitroo提供的产品，您还需同意和遵守该产品的服务协议。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  一、基本事项
               </div>
               1.1
               Bitroo保留随时自行决定修改本协议条款的权利。您有责任定期查看本协议以了解最新修改。本协议及其修改在
               Bitroo将其公布于Bitroo网站之时即刻生效（除非另行规定生效时间）。您在本协议及其修改发布后继续使用Bitroo提供的服务或Bitroo网站即表示您接受并同意该修改。
               <br />
               1.2
               若您对本协议的修改有任何异议，或对Bitroo提供的服务和/或Bitroo网站不满意，您应立即：（1）停止使用Bitroo提供的服务和Bitroo网站;
               并（2）通过客服管道告知Bitroo停止服务。终止服务后，使用者使用Bitroo服务的权利立即终止。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  二、用户注册
               </div>
               2.1
               用户特此向公司作出如下陈述和保证，并且该等陈述和保证将被视为于使用者每次使用Bitroo的服务时重新作出：
               <br />
               （1）如果用户为自然人，用户已年满18周岁并具有完全民事行为能力；
               <br />
               （2）如若使用者为实体，使用者根据其实体所在司法管辖区的法律法规合法设立并有效存在，信誉良好。特别的，用户还应保证不存在中华人民共和国境内居民利用该实体注册及使用公司服务的情形；
               <br />
               （3）使用者接受并使用Bitroo提供的服务在使用者的所在国家/地区符合适用法律法规和相关政策，且不违反用户对于任何其他合作厂商的义务；
               <br />
               （4）使用者具备使用Bitroo提供的服务并履行相关义务所需的权力，并且本协议和相关服务购买订单对使用者有效并具有约束力。
               <br />
               2.2 对于您提供的注册信息和信息（“注册信息”），您同意：
               <br />
               （1）提供合法、真实、准确和完整的注册信息；
               <br />
               （2）如果已提供的注册信息有任何变化，您将及时通知Bitroo。如果您提供的注册信息是非法、不真实、不准确或不完整的，您应承担相应的全部责任和后果；
               <br />
               （3）Bitroo保留根据您提供的信息以及Bitroo的合规义务和内部风险控制政策对帐户施加某些限制和/或暂停或终止您使用Bitroo服务的权利；
               <br />
               （4）除非获得Bitroo事先书面同意，否则每位用户只能在Bitroo注册一个帐户；
               <br />
               （5）为KYC（“了解您的客户”）和/或反洗钱等合规目的，Bitroo有权要求您提供帐户所有者的身份识别信息和/或财务信息；
               <br />
               （6）您在使用Bitroo提供的服务中的一些特定功能时需要输入您的用户名和密码。如果您因为忘记密码等原因无法访问您的帐户，Bitroo为确定帐户真实的所有权，有权要求您提供包括身份识别信息等在内的特定信息，包括但不限于：身份证明、居住证明、电话号码/电子邮件证明以及网站上的任何可识别活动信息，例如交易ID、订单号、提款金额等。您承诺您不会与其他人分享您的用户名和/或密码或允许任何其他合作厂商使用您的帐户。对于您或任何其他合作厂商对您的帐户或密码的不当使用以及所导致的后果和损失，Bitroo不承担任何责任。如果您认为您的用户名和/或密码被他人知晓或帐户已被他人擅自使用，您应立即通知Bitroo。
               <br />
               2.3
               您理解并认可，Bitroo有权根据相关法律法规的规定或监管部门的要求提供您的全部或部分注册信息。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  三、风险通知
               </div>
               3.1
               您理解并认可：持有、交易、投资数字资产以及使用数字资产相关的服务会涉及风险，本第3.1条列出的风险并非穷尽。这些风险以及现在或将来出现的其他风险可能会使得您无法获得任何收益，遭受财务损失，甚至使得我们提供的服务被终止：
               <br />
               （1）使用任何数字资产或相关的产品和服务都会带来潜在的财务、监管和其他风险。您在使用任何数字资产或相关的产品和服务前应确认您对区块链技术、数字资产以及智慧合约的功能和风险方面具有充分的知识和经验。您参与任何数字资产活动和/或使用相关服务的任何决定应基于您认为必要的独立调查和/或获取专业人士的咨询。
               Bitroo预设您在使用本服务时是一名了解数字资产行业并且懂得数字资产相关产品和服务风险的专业人士，本协议以及Bitroo提供的任何服务均不应视为Bitroo向您提供投资建议；
               <br />
               （2）任何Bitroo提供的产品或服务的价值以及任何数量的任何数字资产都可能因不受Bitroo控制的许多因素而受影响，这些因素包括但不限于：挖矿难度和/或其他挖矿参数/属性的变化，数字资产的市场价格波动，硬体（例如：矿机）过时和硬体摊销等；
               <br />
               （3）由于数字资产的性质，任何数字资产的价值可能随时损失其部分甚至全部价值。您在此确认知悉任何一种数字资产的价值都可能会迅速下降（甚至下降为零）。对于任何数字资产的市场价格波动所造成的任何和所有损失，Bitroo不承担任何责任；
               <br />
               （4）Bitroo会不时对于系统进行维护或升级。您确认您并未期待Bitroo提供的服务是连续的、不中断的。除双方另有明确约定，否则Bitroo对于其系统的维护和升级而导致的暂停和中断所造成的任何和所有损失，Bitroo不承担责任；
               <br />
               （5）没有一种身份验证，或电脑安全技术是100%安全的。您同意承担骇客入侵或身份盗用相关的所有风险；
               <br />
               （6）您了解并同意，Bitroo保留通过网站、电子邮件、客户服务电话、手机短信和其他官方管道发布、修改和/或提供与服务有关的信息的权利。对于通过非Bitroo官方管道获得的信息，Bitroo概不负责。如果您对以Bitroo名义发送的信息的真实性有任何疑问，请立即与我们联系。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  四、数字资产的使用
               </div>
               4.1
               您不得以任何适用于您的法律法规所禁止的方式开采、购买、出售、交换、持有及/或以其他方式使用任何数字资产。
               <br />
               4.2
               在购买Bitroo提供的任一服务套餐之前，您应判断该服务套餐是否适合您的财务能力和风险偏好。您购买Bitroo提供的任一服务套餐，即代表您认可、理解并接受与开采、购买、出售、交换、持有及/或使用数字资产相关的全部风险，包括但不限于：
               <br />
               （1）数字资产价格大幅波动：包括比特币在内的任何数字资产的价格可能急剧下跌，甚至可能跌至为零；
               <br />
               （2）数字资产交易可能需要一段时间被确认。虽然可能性较低，但的确存在可能某些数字资产交易可能永远无法被确认。未被确认的数字资产交易代表该等交易未完成；
               <br />
               （3）数字资产交易是不可逆转的：如果您向任何错误的人或数字资产钱包位址发送任何数量的数字资产，您将无法撤销交易或收回这些资金;
               <br />
               （4）如果您丢失或忘记访问和使用这些数字资产所需的任何PIN或密码，该等数字资产可能会丢失或无法被您使用;
               <br />
               （5）区块链（或数字资产）技术可能存在固有的未知技术缺陷；
               <br />
               （6）各国家/地区可能会出台和颁布影响使用数字资产的新法律、规定和政策。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  五、限制和义务
               </div>
               5.1 您不得利用Bitroo提供的服务从事任何违法活动。您不得利用Bitroo提供的服务：
               <br />
               （1）支持、煽动或参与任何恐怖主义活动；
               <br />
               （2）参与任何洗钱活动；
               <br />
               （3）捏造或歪曲事实、散布谣言、扰乱社会秩序;
               <br />
               （4）宣传或制作淫秽色情、暴力及/或恐怖内容；
               <br />
               （5）参与任何其他违反适用法律法规的活动。
               <br />
               5.2
               您同意遵守所有适用法律法规、本协议以及Bitroo不时宣布的与使用Bitroo网站和Bitroo提供的服务相关的所有规则和政策。
               <br />
               5.3
               您帐户密码（或者其他安全设备）的安全，完全由您负责。通过您帐户提交的申请，订单，均被视为经您签署并同意。由您过失导致帐户、密码或者其他信息被冒用或非法使用的，我们不对产生的任何风险和损失承担任何责任。若您怀疑您的帐户、密码或者其他信息被冒用或非法使用的，您应立即通知我们。
               <br />
               5.4
               您承诺购买Bitroo的产品使用的资金来源合法合规，您同意您应完全承担通过使用Bitroo提供的服务而产生的相关税收义务。
               <br />
               5.5
               您同意并承诺您仅仅在为自己的利益使用Bitroo提供的服务，您并未代他人或为了其他合作厂商的利益而使用Bitroo提供的服务。
               <br />
               5.6
               您同意在您发现或怀疑任何合作厂商使用Bitroo提供的服务存在任何欺诈或不当行为时立即与Bitroo联系。
               <br />
               5.7
               除非Bitroo事先明确书面同意，否则您不得以任何方式在Bitroo网站注册多个帐户。您不得通过注册多个帐户以获取利润、奖励或优惠券。
               <br />
               5.8 您不应参与任何可能影响Bitroo提供的服务及/或Bitroo网站安全的活动。
               <br />
               5.9
               您不得使用任何方法来遮罩您的互联网流量和IP位址（例如Proxy、Tor、VPN等）或使用其他隐藏用户真实互联网连接的技术服务。
               <br />
               5.10
               在Bitroo网站上展示的任何内容的知识产权，包括但不限于文章、图片、新闻、材料、网站结构、网站布置、网站设计，除非特别说明，均由Bitroo或Bitroo的许可人（如有）完全拥有。用户应尊重Bitroo以及
               Bitroo的许可人（如有）的知识产权。
               <br />
               5.11
               公司特此在本协议期间，向您授予一项有限、非排他性、可撤销、不可转让并且不可转授的许可，允许您按照本协议条款使用我们提供的产品。公司或其许可方保留服务的一切权利、所有权和权益，包括所有原始程式码、目标代码、信息、信息，其中体现的版权、商标、专利、发明和商业秘密，以及本协议下未明确向您授予的所有其他权利。本协议概不构成放弃公司在英属维京群岛或者任何其他管辖地知识产权法或者任何其他联邦、州或外国法律项下的任何权利。
               <br />
               5.12
               未经Bitroo事先书面授权，用户不得以Bitroo的名义进行任何活动和使用Bitroo网站信息/信息。
               <br />
               5.13 对于我们就您使用产品过程中，我们向您提供的任何信息或信息，您特此同意：
               <br />
               a. 该等信息或信息仅用于本协议规定的用途，并且其使用符合所有适用法律。
               <br />
               b.
               除适用法律要求外，不向合作厂商传播、传输、展示或者以其他方式披露或提供该等信息或信息；
               <br />
               c.
               未经公司事先书面同意，不改进、修改该等信息或信息，不使用该等信息或信息制作衍生品，亦不将该等信息或信息与任何其他信息组合使用。
               <br />
               5.14您在此承诺并保证您使用Bitroo提供的服务不会对Bitroo声誉造成负面影响或使得Bitroo承担法律责任或其他处罚，罚款及制裁。
               <br />
               5.15 您理解Bitroo提供的服务不适用于以下（下称“受限人士”）:
               1）中国大陆、克里米亚地区、古巴、伊朗、朝鲜和叙利亚的居民；2）根据适用的贸易制裁和出口合规法律，受到限制的任何实体或个人；
               3）其他不符合Bitroo合规义务和/或内部风控政策的任何实体或个人。如您使用Bitroo提供的矿机共用服务，受限人士还包括美国的居民。上述名录可能并不穷尽。在使用Bitroo提供的服务前，请确认您不属于受限人士。如您属于受限人士且您使用Bitroo提供的服务，由此导致的所有法律风险和责任将完全由您独立承担，同时Bitroo有权拒绝向您提供服务，您无权要求Bitroo退还任何款项。
               <br />
               5.16如果用户违反本第5条的规定，Bitroo有权立即暂停或终止向使用者提供服务，并有权在适用法律法规允许的范围内采取一切必要的法律手段。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  六、违约责任
               </div>
               6.1
               对于使用者任何违反本协议的行为（包括但不限于：用户未及时支付套餐费用，使用者使用Bitroo提供的服务在使用者所在国家/地区系非法等），Bitroo有权立即暂停或终止为使用者提供服务并在适用法律法规允许的范围内采取一切必要的法律手段，包括但不限于要求用户赔偿Bitroo受到的相关损失。
               <br />
               6.2
               如果Bitroo合理怀疑用户以违法，欺诈或其他不正当方式使用Bitroo提供的服务，Bitroo有权暂停向使用者提供服务，直到使用者能够提供符合Bitroo要求的合规证明。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  七、责任限制
               </div>
               7.1
               Bitroo对于其提供的产品的责任限制以该产品的服务协议为准。如无特别约定，Bitroo对使用者的全部责任不超过该使用者向Bitroo支付的全部费用。
               <br />
               7.2
               对于因其无法控制的情况而导致的服务中断或终止，Bitroo不承担任何责任，包括但不限于战争（宣告或未宣告）、恐怖活动、破坏行为、封锁、火灾、闪电、天灾、罢工、骚乱、暴动、内乱、防疫限制、流行病、地震、洪水、飓风、爆炸以及监管和行政行动或延误。如果发生此类不可抗力，Bitroo应采取合理的措施以最大程度地减少干扰和损失，但对于此类不可抗力造成的或附带的后果不承担任何责任。
               <br />
               7.3
               用户同意：Bitroo提供的任何合作厂商链接只是出于便利和参考的作用。Bitroo提供该等合作厂商链接不代表其确认该等链接以及该等链接上显示的内容。由于该等合作厂商链接不受Bitroo控制，Bitroo无法保证使用者访问该等链接是安全、不带病毒的。使用者访问该等合作厂商链接以及任何其他非受Bitroo控制的合作厂商链接导致的损失Bitroo不负任何责任。
               <br />
               7.4
               用户了解并同意：除非Bitroo事先明确书面同意，否则在任何情况下，Bitroo亦不就以下事项以及用户由于以下事项导致的损失承担责任：
               <br />
               （1）交易利润损失；
               <br />
               （2）服务中断（该等中断非因Bitroo故意不当行为导致）；
               <br />
               （3）非因Bitroo故意不当行为导致的信息/信息的损坏或丢失；
               <br />
               （4）算力消亡或退出市场；
               <br />
               （5）法律法规及政策因素的执行或变化；
               <br />
               （6）根据使用者提供的信息（包括但不限于身份识别信息、财务信息、数字资产钱包位址等），用户不符合Bitroo的合规义务和/或内部风控政策；
               <br />
               （7）使用者过错或违反本协议导致的损失（包括但不限于：向Bitroo提供不准确的信息，向合作厂商泄漏密码等）；
               <br />
               （8）其他不受Bitroo控制的事件。
               <br />
               7.5
               您同意并接受：在与本协议规定的任何其他补偿或赔偿权利不重复的前提下，您应向公司及其母公司、子公司和附属公司，以及其各自的管理人员、董事、雇员、代理人和顾问赔偿您违反本协议（包括但不限于您违反本协议项下的任何义务、陈述或保证）导致的任何损失、责任、索赔、指控、行动、诉讼或费用（统称“损失”，包括合理的律师费），并就此向公司及其母公司、子公司和附属公司，以及其各自的管理人员、董事、雇员、代理人和顾问提供辩护，保证公司及其母公司、子公司和附属公司，以及其各自的管理人员、董事、雇员、代理人和顾问不因此遭受该等损害。在任何情况下，公司或其母公司、子公司或附属公司，或其各自的管理人员、董事、雇员、代理人或顾问均不对您或任何合作厂商承担任何与本协议有关的特殊、偶然、间接或结果性的损害或利润损失。
               <br />
               八、其他约定
               <br />
               8.1
               弃权：公司对本协议项下使用者的任何违约行为延迟行使或者疏于行使公司的任何权利或救济，不应解释为公司豁免或者默许任何该等违约行为。
               <br />
               8.2
               可分割性：若本协议的任何条款被认定不合法或者不可执行，则该认定应不影响本协议的其余条款。
               <br />
               8.3 适用法律：本协议应全面适用英属维京群岛法律，但不考虑其法律冲突原则。
               <br />
               8.4 争议解决：如果各方在本协议履行过程中发生任何争议,
               如协商不成，任何一方有权提交由新加坡国际仲裁中心仲裁，并按照提交仲裁时有效的《新加坡国际仲裁中心仲裁规则》最终解决。仲裁地为新加坡。仲裁庭应由三
               (3)
               名仲裁员组成。仲裁语言为英语。仲裁裁决是终局的，对双方均具有约束力。除非仲裁裁决另行裁定，仲裁费应由败诉一方负担。败诉方还应补偿胜诉方的律师费等支出。
               <br />
               8.5 完整协议。本协议构成您与 Bitroo
               之间的完整法律协议，并约束您对服务的使用，并完全取代您与 Bitroo
               之前就服务达成的任何协议。
            </p>
         )}

         {language !== 'zh' && (
            <p style={{ margin: 10, fontSize: 14, lineHeight: '26px' }}>
               <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16 }}>
                  Service Agreement
               </div>
               <br />
               The 'Bitroo Service Agreement' ('the agreement') is an agreement between Bitroo
               ('Bitroo' or 'we' or 'the company') and the users ('users' or 'you') of the services
               provided by Bitroo and/or the official website of Bitroo [Bitroo.com] ('the Bitroo
               website'). This agreement outlines the rights and responsibilities of Bitroo and its
               users. By using the services provided by Bitroo and/or the Bitroo website, you
               indicate your agreement to all the terms and conditions set forth in this agreement.
               If you do not agree, please refrain from using the services provided by Bitroo and
               the Bitroo website. If you purchase a product provided by Bitroo, you must also agree
               to and comply with the service agreement specific to that product.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  1. Basic Matters
               </div>
               1.1 Bitroo reserves the right to modify the terms of this agreement at any time at
               its sole discretion. It is your responsibility to periodically review this agreement
               for the latest modifications. This agreement and its amendments shall take effect
               immediately upon Bitroo posting them on the Bitroo website (unless otherwise
               specified effective time). Your continued use of the services provided by Bitroo or
               the Bitroo website following the posting of this agreement and its modifications
               constitutes your acceptance and consent to such modifications.
               <br />
               1.2 If you have any objections to the modifications made to this agreement, or if you
               are dissatisfied with the services and/or the Bitroo website, you should immediately:
               (1) cease using the services and the Bitroo website; and (2) inform Bitroo of your
               decision to terminate the services through the customer support channel. Upon
               termination of the services, the user's right to use Bitroo services is immediately
               terminated.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  2.User Registration
               </div>
               2.1 The user hereby makes the following representations and warranties to the
               company, and such representations and warranties shall be deemed to be made by the
               User each time they use Bitroo's services:
               <br />
               (1) If the user is a natural person, they are at least 18 years of age and have full
               legal capacity to enter into contractual obligations.
               <br />
               (2) If the user is an entity, it is legally established and validly existing under
               the laws and regulations of its jurisdiction, with a good reputation. In particular,
               the user also warrants that there is no situation where residents within the
               territory of the People's Republic of China utilize the entity to register and use
               the company's services.
               <br />
               (3) The user accepts and uses the services provided by Bitroo in compliance with
               applicable laws, regulations, and relevant policies in their country/region, and does
               not violate any obligations towards any other cooperating parties.
               <br />
               (4) The user has the authority to use the services provided by Bitroo and fulfill the
               associated obligations, and this agreement and any related service purchase orders
               are valid and binding upon the user.
               <br />
               2.2 Regarding the registration information and information provided by you
               ("Registration Information"), you agree to:
               <br />
               (1) Provide legal, true, accurate, and complete registration information.
               <br />
               (2) Promptly notify Bitroo if there are any changes to the provided registration
               information. If the registration information provided by you is illegal, untrue,
               inaccurate, or incomplete, you shall bear all corresponding responsibilities and
               consequences.
               <br />
               (3) Bitroo reserves the right to impose certain restrictions on your account and/or
               suspend or terminate your use of Bitroo services based on the information you
               provide, Bitroo's compliance obligations, and internal risk control policies.
               <br />
               (4) Each user may only register one account on Bitroo unless otherwise agreed in
               writing by Bitroo.
               <br />
               (5) For KYC (Know Your Customer) and/or anti-money laundering compliance purposes,
               Bitroo has the right to request identification information and/or financial
               information of the account owner.
               <br />
               (6) When using certain specific features of the services provided by Bitroo, you may
               need to enter your username and password. If you are unable to access your account
               due to reasons such as forgetting your password, Bitroo, in order to ascertain the
               true ownership of the account, has the right to request specific information from
               you, including but not limited to identification proof, proof of residence, telephone
               number/email proof, and any identifiable activity information on the website, such as
               transaction IDs, order numbers, withdrawal amounts, etc. You undertake not to share
               your username and/or password with anyone else or allow any other cooperating parties
               to use your account. Bitroo shall not be liable for any consequences and losses
               arising from your improper use of your account or password, or any improper use by
               any other cooperating parties. If you believe that your username and/or password has
               been disclosed to others or that your account has been used without authorization,
               you shall immediately notify Bitroo.
               <br />
               2.3 2.3 You understand and acknowledge that Bitroo has the right to provide all or
               part of your registration information in accordance with applicable laws,
               regulations, or regulatory authorities' requirements.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  3.Risk Notification
               </div>
               3.1 You acknowledge and understand that holding, trading, investing in digital
               assets, and using services related to digital assets involve risks. The risks listed
               in section 3.1 are not exhaustive. These risks, as well as other risks that may arise
               now or in the future, may prevent you from obtaining any returns, result in financial
               losses, or even lead to the termination of the services we provide.
               <br />
               (1) The use of any digital assets or related products and services will bring
               potential financial, regulatory and other risks. Before using any digital assets or
               related products and services, you should confirm that you have sufficient knowledge
               and experience in the functions and risks of blockchain technology, digital assets
               and smart contracts. Any decision by you to participate in any digital asset
               activities and/or use related services should be based on independent investigation
               and/or professional consultation as you deem necessary. Bitroo assumes that you are a
               professional who understands the digital asset industry and understands the risks of
               digital asset-related products and services when using this service. This agreement
               and any services provided by Bitroo shall not be deemed as Bitroo providing
               investment advice to you;
               <br />
               (2) The value of any product or service provided by Bitroo and any amount of any
               digital asset may be affected by many factors beyond the control of Bitroo, including
               but not limited to: mining difficulty and/or other mining parameters / Changes in
               attributes, market price fluctuations of digital assets, hardware (eg: mining
               machine) obsolescence and hardware amortization, etc.;
               <br />
               (3) Due to the nature of digital assets, the value of any digital asset may lose some
               or even all of its value at any time. You hereby acknowledge that the value of any
               digital asset may drop rapidly (even to zero). Bitroo shall not be liable for any and
               all losses arising from market price fluctuations of any digital asset;
               <br />
               (4) Bitroo will maintain or upgrade the system from time to time. You confirm that
               you do not expect the services provided by Bitroo to be continuous and uninterrupted.
               Unless otherwise expressly agreed by both parties, Bitroo shall not be liable for any
               and all losses caused by the suspension and interruption of its system maintenance
               and upgrade;
               <br />
               (5) No identity verification or computer security technology is 100% safe. You agree
               to assume all risks associated with hacking or identity theft;
               <br />
               (6) You understand and agree that Bitroo reserves the right to publish, modify and/or
               provide service-related information through websites, emails, customer service calls,
               SMS and other official channels. Bitroo is not responsible for information obtained
               through non-Bitroo official channels. If you have any doubts about the authenticity
               of information sent on behalf of Bitroo, please contact us immediately.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  4. Use of Digital Assets
               </div>
               4.1 You may not mine, buy, sell, exchange, hold and/or otherwise use any digital
               assets in a manner prohibited by any applicable laws and regulations.
               <br />
               4.2 Before purchasing any service package provided by Bitroo, you should judge
               whether the service package is suitable for your financial ability and risk
               preference. When you purchase any service package provided by Bitroo, you
               acknowledge, understand and accept all the risks associated with mining, buying,
               selling, exchanging, holding and/or using digital assets, including but not limited
               to:
               <br />
               (1) The price of digital assets fluctuates sharply: the price of any digital asset,
               including Bitcoin, may drop sharply, or even drop to zero;
               <br />
               (2) Digital asset transactions may take a while to be confirmed. Although less
               likely, it is possible that some digital asset transactions may never be confirmed.
               An unconfirmed digital asset transaction means that the transaction has not been
               completed;
               <br />
               (3) Digital asset transactions are irreversible: if you send any amount of digital
               assets to any wrong person or digital asset wallet address, you will not be able to
               reverse the transaction or recover those funds;
               <br />
               (4) If you lose or forget any PIN or password required to access and use these
               digital assets, such digital assets may be lost or unavailable to you;
               <br />
               (5) Blockchain (or digital asset) technology may have inherent unknown technical
               flaws;
               <br />
               (6) Countries/regions may introduce and promulgate new laws, regulations and policies
               that affect the use of digital assets.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  5. Restrictions and Obligations
               </div>
               5.1 You shall not use the services provided by Bitroo to engage in any illegal
               activities. You may not take advantage of the services provided by Bitroo to:
               <br />
               (1) Support, incite or participate in any terrorist activities;
               <br />
               (2) Participate in any money laundering activities;
               <br />
               (3) Fabricating or distorting facts, spreading rumors, disrupting social order;
               <br />
               (4) Promoting or producing obscene, violent and/or terrorist content;
               <br />
               (5) Participate in any other activities that violate applicable laws and regulations.
               <br />
               5.2 You agree to abide by all applicable laws and regulations, this agreement, and
               all rules and policies that Bitroo may announce from time to time in relation to the
               use of the Bitroo website and the services provided by Bitroo.
               <br />
               5.3 The security of your account password (or other security devices) is entirely
               your responsibility. Applications and orders submitted through your account are
               deemed to be signed and agreed by you. If your account, password or other information
               is used fraudulently or illegally due to your negligence, we shall not be liable for
               any risks and losses incurred. If you suspect that your account, password or other
               information has been used fraudulently or illegally, you should notify us
               immediately.
               <br />
               5.4 You promise that the sources of funds used to purchase Bitroo’s products are
               legal and compliant, and you agree that you shall fully bear the relevant tax
               obligations arising from the use of the services provided by Bitroo.
               <br />
               5.5 You agree and promise that you are only using the services provided by Bitroo for
               your own benefit, and you are not using the services provided by Bitroo on behalf of
               others or for the benefit of other cooperative manufacturers.
               <br />
               5.6 You agree to contact Bitroo immediately when you discover or suspect any
               fraudulent or improper behavior of any partner using the services provided by Bitroo.
               <br />
               5.7 You may not in any way register for multiple accounts on the Bitroo website
               unless Bitroo expressly agrees in advance in writing. You may not register for
               multiple accounts in order to receive profits, rewards or coupons.
               <br />
               5.8 You should not engage in any activities that may affect the services provided by
               Bitroo and/or the security of the Bitroo website.
               <br />
               5.9 You may not use any method to mask your Internet traffic and IP address (such as
               Proxy, Tor, VPN, etc.) or use other technical services that hide the user's real
               Internet connection.
               <br />
               5.10 The intellectual property rights of any content displayed on the Bitroo website,
               including but not limited to articles, pictures, news, materials, website structure,
               website layout, website design, unless otherwise specified, are owned by Bitroo or
               Bitroo's licensors (if any) Fully owned. Users shall respect the intellectual
               property rights of Bitroo and Bitroo's licensors (if any).
               <br />
               5.11 The company hereby grants you a limited, non-exclusive, revocable,
               non-transferable and non-sublicensable license during the term of this agreement,
               allowing you to use the products we provide in accordance with the terms of this
               agreement. Company or its licensors retain all right, title and interest in and to
               the services, including all source code, object code, information, information,
               copyrights, trademarks, patents, inventions and trade secrets embodied therein, and
               all rights and interests not expressly granted to you hereunder. All other rights
               granted. Nothing in this agreement shall constitute a waiver of any of the company's
               rights under the intellectual property laws of the British Virgin Islands or any
               other jurisdiction, or any other federal, state or foreign laws.
               <br />
               5.12 Without the prior written authorization of Bitroo, the user shall not conduct
               any activities and use Bitroo website information/information in the name of Bitroo.
               <br />
               5.13 With respect to any information or information we provide to you in connection
               with your use of the products, you hereby agree that:
               <br />
               a. Such information or information will only be used for the purposes specified in
               this agreement and its use will comply with all applicable laws.
               <br />
               b. Except as required by applicable laws, not to disseminate, transmit, display or
               otherwise disclose or provide such information or information to the cooperative
               vendor;
               <br />
               c. Not to improve, modify, create derivative works from, or combine such information
               or information with any other information without the prior written consent of the
               Company.
               <br />
               5.14 You hereby promise and guarantee that your use of the services provided by
               Bitroo will not negatively affect the reputation of Bitroo or cause Bitroo to bear
               legal liability or other penalties, fines and sanctions.
               <br />
               5.15 You understand that the services provided by Bitroo are not applicable to the
               following (hereinafter referred to as "Restricted Persons"): 1) Residents of mainland
               China, Crimea, Cuba, Iran, North Korea and Syria; 2) According to applicable trade
               sanctions and Any entity or individual subject to export compliance laws; 3) Any
               other entity or individual that does not comply with Bitroo's compliance obligations
               and/or internal risk control policies. If you use the mining machine sharing service
               provided by Bitroo, the restricted persons also include residents of the United
               States. The above list may not be exhaustive. Before using the services provided by
               Bitroo, please confirm that you are not a restricted person. If you are a restricted
               person and you use the services provided by Bitroo, all legal risks and
               responsibilities arising therefrom will be solely borne by you. At the same time,
               Bitroo has the right to refuse to provide services to you, and you have no right to
               ask Bitroo to refund any money.
               <br />
               5.16 If the user violates the provisions of this Article 5, Bitroo has the right to
               immediately suspend or terminate the provision of services to the user, and has the
               right to take all necessary legal measures within the scope permitted by applicable
               laws and regulations.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  6. Liability for Breach of Contract
               </div>
               6.1 For any violation of this agreement by the user (including but not limited to:
               the user fails to pay the package fee in time, the service provided by the user using
               Bitroo is illegal in the country/region where the user is located, etc.), Bitroo has
               the right to immediately suspend or terminate the service users provide services and
               take all necessary legal means within the scope permitted by applicable laws and
               regulations, including but not limited to requiring users to compensate Bitroo for
               related losses.
               <br />
               6.2 If Bitroo reasonably suspects that the user is using the services provided by
               Bitroo in an illegal, fraudulent or other improper manner, Bitroo has the right to
               suspend the provision of services to the user until the user can provide proof of
               compliance that meets Bitroo's requirements.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  7. Limitation of Liability
               </div>
               7.1 The limitation of Bitroo's liability for the products it provides is subject to
               the service agreement of the products. If there is no special agreement, Bitroo's
               total liability to the user shall not exceed all the fees paid by the user to Bitroo.
               <br />
               7.2 Bitroo shall not be liable for any interruption or termination of the service due
               to circumstances beyond its control, including but not limited to war (declared or
               undeclared), terrorism, sabotage, blockade, fire, lightning, acts of God, strikes,
               Riots, riots, civil disturbances, epidemic restrictions, epidemics, earthquakes,
               floods, hurricanes, explosions, and regulatory and administrative actions or delays.
               In the event of such force majeure, Bitroo shall take reasonable measures to minimize
               disturbance and loss, but shall not be liable for any consequences caused or
               incidental to such force majeure.
               <br />
               7.3 User agrees: Any links to cooperative manufacturers provided by Bitroo are only
               for convenience and reference. Bitroo's provision of such cooperative vendor links
               does not mean that it confirms such links and the content displayed on such links.
               Since the links of these cooperative manufacturers are not controlled by Bitroo,
               Bitroo cannot guarantee that users' access to these links is safe and free of
               viruses. Bitroo is not responsible for any loss caused by the user's access to the
               links of these partners and any other links of partners not under the control of
               Bitroo.
               <br />
               7.4 The user understands and agrees: Unless Bitroo expressly agrees in advance in
               writing, under no circumstances shall Bitroo be liable for the following matters and
               the losses caused by the user due to the following matters:
               <br />
               (1) Loss of trading profits;
               <br />
               (2) service interruption (such interruption is not caused by Bitroo's intentional
               misconduct);
               <br />
               (3) Damage or loss of information/information not caused by Bitroo’s intentional
               misconduct;
               <br />
               (4) Computing power dies or exits the market;
               <br />
               (5) Implementation or changes of laws, regulations and policy factors;
               <br />
               (6) According to the information provided by the user (including but not limited to
               identification information, financial information, digital asset wallet address,
               etc.), the user does not comply with Bitroo's compliance obligations and/or internal
               risk control policies;
               <br />
               (7) Losses caused by the user's fault or violation of this agreement (including but
               not limited to: providing inaccurate information to Bitroo, leaking passwords to
               cooperative manufacturers, etc.);
               <br />
               (8) Other events not controlled by Bitroo.
               <br />
               7.5 "You agree and acknowledge that, without prejudice to any other compensation or
               indemnification rights as provided in this agreement, you shall indemnify the company
               and its parent, subsidiary, and affiliated companies, as well as their respective
               managers, directors, employees, agents, and advisors, against any losses,
               liabilities, claims, charges, actions, suits, or expenses (collectively referred to
               as 'Losses,' including reasonable attorney fees) incurred as a result of your breach
               of this agreement (including, but not limited to, any obligations, representations,
               or warranties under this agreement). You also agree to defend the company and its
               parent, subsidiary, and affiliated companies, as well as their respective managers,
               directors, employees, agents, and advisors, and ensure that they are not harmed as a
               result of such losses. In no event shall the company or its parent, subsidiary, or
               affiliated companies, or their respective managers, directors, employees, agents, or
               advisors be liable to you or any third party for any special, incidental, indirect,
               or consequential damages or loss of profits arising out of or in connection with this
               agreement.
               <br />
               8. Other Agreements
               <br />
               8.1 Waiver: The company's delay in exercising or negligence in exercising any of the
               company's rights or remedies for any breach of contract by the user under this
               Agreement shall not be construed as the company's waiver or tacit approval of any
               such breach.
               <br />
               8.2 Severability: If any provision of this agreement is deemed to be illegal or
               unenforceable, such determination shall not affect the remaining provisions of this
               Agreement.
               <br />
               8.3 Applicable Law: This agreement shall be governed by the laws of the British
               Virgin Islands without regard to its principles of conflict of laws.
               <br />
               8.4 Dispute resolution: If any dispute arises between the parties during the
               performance of this agreement, if the negotiation fails, either party has the right
               to submit it to the Singapore International Arbitration Center for arbitration, and
               it will be finally resolved in accordance with the "Singapore International
               Arbitration Center Arbitration Rules" in effect at the time of submission for
               arbitration . The place of arbitration shall be Singapore. The arbitral tribunal
               shall consist of three (3) arbitrators. The language of the arbitration shall be
               English. The arbitration award is final and binding on both parties. Unless otherwise
               determined by the arbitration award, the arbitration fee shall be borne by the losing
               party. The losing party shall also compensate the winning party for its attorney fees
               and other expenses.
               <br />
               8.5 Entire Agreement. This agreement constitutes the entire legal agreement between
               you and Bitroo and governs your use of the services, and completely supersedes any
               prior agreements between you and Bitroo with respect to the services.
            </p>
         )}
      </div>
   );
}

export default UserAgreement;
