import React, { forwardRef, useEffect, useState } from 'react';
import I18n from '../../language';
import { useSelector } from 'react-redux';
import MealBox from './MealBox';
import { getCloudMiner, typeEnum, getMiner } from '../../services/user';
import { Skeleton, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import DownloadModal from './components/downloadModal';
import { store } from '../../store';
import { QuestionCircleOutlined } from '@ant-design/icons';

const Product = forwardRef((props, ref) => {
   const [list, setList] = useState([]);
   const [modalShow, setModalShow] = useState(false);
   const [mineList, setMineList] = useState([]);
   const [leaseMineList, setLeaseMineList] = useState([]);
   const { language } = useSelector(store => store.user);
   const navigate = useNavigate();
   const productTrait = {
      0: '低能耗',
      1: '最实惠',
      2: '高产量',
      3: '回本快',
      4: '火爆款',
      5: '总价低',
   };
   store.subscribe(() => {
      const { language } = store.getState().user;
      if (currentLang && language !== currentLang) {
         getData();
      }
   });
   let currentLang = null;
   function getData() {
      currentLang = I18n.locale;
      getCloudMiner({ isRecommendWeb: 1, type: 'all' }).then(res => {
         if (res.data.code === 0) {
            const data = handlerListData(res.data.data);
            setList(data);
         }
      });
      getMiner({ isRecommendWeb: true, isHideError: true, type: 'market' }).then(res => {
         if (res.data.code === 0) {
            const data = res.data.data.splice(0, 4);
            setMineList(data);
         }
      });
      getMiner({ isRecommendWeb: true, isHideError: true, type: 'lease' }).then(res => {
         if (res.data.code === 0) {
            const data = res.data.data.splice(0, 4);
            setLeaseMineList(data);
         }
      });
   }
   const handlerListData = list => {
      const obj = {};
      list.forEach(item => {
         const type = item.type;
         if (type in obj) {
            if (obj[type].length < 4) obj[type].push(item);
         } else {
            obj[type] = [item];
         }
      });
      return obj;
   };
   useEffect(() => {
      setTimeout(() => {
         getData();
      }, 500);
   }, []);

   return (
      <div className="product view" key={language}>
         <h2>{I18n.t('算力套餐')}</h2>

         <div className="box">
            {!Object.keys(list).length && <Skeleton active />}
            {Object.keys(typeEnum).map(keyType => {
               return list[keyType] && list[keyType].length > 0 ? (
                  <div key={keyType}>
                     <h3 className="sub-title">{I18n.t(typeEnum[keyType].text)}</h3>
                     <div className="list">
                        {list[keyType]?.map(item => (
                           <MealBox
                              key={item.id}
                              item={item}
                              onClick={() => {
                                 navigate('/details/' + item.id);
                              }}
                              style={{ cursor: 'pointer' }}
                           />
                        ))}
                     </div>
                  </div>
               ) : null;
            })}
         </div>
         <div className="product-main">
            <h2>{I18n.t('矿机套餐')}</h2>
            {/* <>
               <h3 className="sub-title">{I18n.t('矿机租赁')}</h3>
               <div className="box">
                  <div className="list" style={{ marginBottom: '80px' }}>
                     {!Object.keys(leaseMineList).length && <Skeleton active />}
                     {leaseMineList.map(list => {
                        return (
                           <div
                              className="procure-mine-box mine-box"
                              onClick={() => {
                                 setModalShow(true);
                              }}
                           >
                              <div className="img-wp">
                                 <img src={list.image} alt="" />
                              </div>
                              <div className="details-wp">
                                 <div className="name">
                                    {list.model}&nbsp;
                                    {list.power}T&nbsp;
                                    {list.effectTime}
                                    {I18n.t('天')}
                                 </div>
                                 <p>
                                    <span>{I18n.t('租赁费')}:</span>
                                    <span className="ml6 val">{list.leasePrice} USDT/T</span>
                                 </p>
                                 <p>
                                    <span>{I18n.t('服务费')}:</span>
                                    <span className="ml6 val">{list.servicePrice} USDT/kWh</span>
                                 </p>
                                 <div className="buttom">
                                    <span>{I18n.t('静态回本天数')}</span>
                                    <Tooltip
                                       title={I18n.t(
                                          '静态回本天数=期初投入/(静态产出数量*币种价格-期中投入)*套餐周期'
                                       )}
                                    >
                                       <QuestionCircleOutlined className="ml6" />
                                    </Tooltip>
                                    <span className="ml6 val">
                                       {list.returnTime}
                                       {I18n.t('天')}
                                    </span>
                                 </div>
                              </div>
                           </div>
                        );
                     })}
                  </div>
               </div>
            </> */}
            <>
               <h3 className="sub-title">{I18n.t('矿机采购')}</h3>
               <div className="box">
                  <div className="list">
                     {!Object.keys(mineList).length && <Skeleton active />}
                     {mineList.map(list => {
                        return (
                           <div
                              key={list.id}
                              className="mine-box"
                              onClick={() => {
                                 setModalShow(true);
                              }}
                           >
                              <div className="svg-label" style={{ color: list.showColor }}>
                                 <div className="after"></div>
                                 <div className="after-maker"></div>
                                 <p>{I18n.t(productTrait[list.productTrait])}</p>
                              </div>
                              <div className="img-wp">
                                 <img src={list.image} alt="" />
                              </div>
                              <div className="details-wp">
                                 <div className="name">{list.showName}</div>
                                 <div className="des-wp">
                                    {/* <div className="tag-label">
                                    {I18n.t('每日预估产出')} {list.yield} {list.currency}
                                 </div> */}
                                    <div className="des-label">
                                       <span>{list.traitDescribe}</span>
                                    </div>
                                 </div>
                                 <div className="coin">{`${list.traitValue}`}</div>
                                 <div className="tag-wp">
                                    <div className="tag" style={{ color: list.payTypeColor }}>
                                       {I18n.t('可分期')}
                                    </div>
                                    {list.poolSupport ? (
                                       <div className="tag red">{I18n.t('矿池打币')}</div>
                                    ) : null}
                                 </div>
                              </div>
                           </div>
                        );
                     })}
                  </div>
               </div>
            </>
         </div>
         <DownloadModal
            show={modalShow}
            closeHandler={() => {
               setModalShow(false);
            }}
         ></DownloadModal>
      </div>
   );
});

export default Product;
