import React, { useState, forwardRef, useEffect } from 'react';
import { Divider, Steps, Button } from 'antd';
import I18n from '../../language';
import SvgIcon from '@/components/SvgIcon';
import ballImg from '../../assets/imgs/platform/ball.png';
import luckImg from '../../assets/imgs/platform/luck.png';
import moonImg from '../../assets/imgs/platform/moon.png';
import hoeImg from '../../assets/imgs/platform/hoe.png';
import coinImg1 from '../../assets/imgs/register/btc.png';
import img1 from '../../assets/imgs/platform/1.png';
import img2 from '../../assets/imgs/platform/2.png';
import img3 from '../../assets/imgs/platform/3.png';
import { useNavigate, useParams } from 'react-router-dom';
// import PlayView from './player';

const Platform = forwardRef((props, ref) => {
   const navigate = useNavigate();
   const [current, setCurrent] = useState(0);
   const onChange = value => {
      console.log('onChange:', value);
      setCurrent(value);
   };
   const list = [
      {
         icon: img1,
         des: '提供便捷的比特币算力租赁和转售服务',
      },
      {
         icon: img2,
         des: '专业比特币矿机销售',
      },
      {
         icon: img3,
         des: '维护和托管服务',
      },
   ];
   useEffect(() => {}, []);
   return (
      <div className="platform view" ref={ref}>
         <div className="introduce">
            <div className="left">
               <h1>{I18n.t('简易挖矿 更高收益')}</h1>

               <div className="sub-text">{I18n.t('关于1')}</div>
               {/* <PlayView /> */}
               <div className="steps-wp" style={{ marginTop: '50px' }}>
                  <Steps
                     direction="vertical"
                     items={[
                        {
                           title: I18n.t('中亚&北美自营矿场，实力雄厚'),
                           icon: <SvgIcon name="step1" />,
                        },
                        {
                           title: I18n.t('专业团队倾力打造，服务至上'),
                           icon: <SvgIcon name="step2" />,
                        },
                        {
                           title: I18n.t('让普通用户轻松参与比特币挖矿'),
                           icon: <SvgIcon name="step3" />,
                        },
                        {
                           title: I18n.t('一键购买云算力，省去繁琐流程'),
                           icon: <SvgIcon name="step4" />,
                        },
                        {
                           title: I18n.t('一站式矿机销售、维护与托管服务'),
                           icon: <SvgIcon name="step5" />,
                        },
                     ]}
                  />
               </div>
               <Button
                  type="primary"
                  onClick={() => {
                     navigate('/register');
                  }}
               >
                  <SvgIcon name="hoe"></SvgIcon>
                  {I18n.t('免费挖矿30天')}
               </Button>
            </div>
            <div className="right">
               <img className="ball" src={ballImg} alt="" />
               <img className="moon" src={moonImg} alt="" />
               <img className="luck" src={luckImg} alt="" />
               <img className="hoe" src={hoeImg} alt="" />
               <img className="coin1" src={coinImg1} alt="" />
               <img className="coin2" src={coinImg1} alt="" />
            </div>
         </div>
         <div className="start-mining">
            <h2>{I18n.t('服务矩阵')}</h2>
            <ul>
               {list.map(data => {
                  return (
                     <li key={data.icon}>
                        <img src={data.icon} alt="" />
                        <div className="des">{I18n.t(data.des)}</div>
                     </li>
                  );
               })}
            </ul>
         </div>
      </div>
   );
});

export default Platform;
