import React from 'react';
import { Skeleton } from 'antd';
import I18n from '../../language';
import { useSelector } from 'react-redux';
import { typeEnum } from '../../services/user';
import { useNavigate } from 'react-router-dom';
import { acMul, formatNumber } from '../../utils';
import coinImg from '../../assets/imgs/register/btc2.png';
import { Button } from 'antd';

export default function Head({ item }) {
   const { language } = useSelector(store => store.user);
   const navigate = useNavigate();

   function getMargin() {
      return language === 'zh' ? '6px' : '0px';
   }

   function getPercent() {
      const total = item.sold + item.stock;

      return ((item.sold / total) * 100).toFixed(0);
   }

   function handleClick() {
      navigate('/register');
   }

   return (
      <div className="section-head">
         <div className="left">
            {item.image ? (
               <img src={item.image} alt="" className="head-img" />
            ) : (
               <Skeleton active className="head-img" />
            )}
         </div>
         <div className="right">
            <div className="head-title">
               <div className="msg">
                  <img src={coinImg} alt="" />
                  <span>{language === 'zh' ? item.name : item.name_en}</span>
                  <span>
                     {item.effect_time}
                     {language === 'zh' ? '' : ' '}
                     {I18n.t('天')}
                  </span>
               </div>
               <div>
                  <div className="tag">{I18n.t(typeEnum[item.type]?.text)}</div>
                  {Number(item.act_price) > 0 && (
                     <span className="tag new" style={{ marginLeft: '10px' }}>
                        {I18n.t('新客专享')}
                     </span>
                  )}
               </div>
            </div>
            <div className="head-bottom">
               <div className="right-cell">
                  <div>
                     <span>{I18n.t('起购算力规格')}</span>
                     <span>{item.min_orders} TH/S</span>
                  </div>
                  <div>
                     <span>{I18n.t('静态产出率')}</span>
                     <span>{acMul(item.output_rate, 100)}%</span>
                  </div>
                  <div>
                     <span>{I18n.t('总供应')}</span>
                     <span>{item.stock + item.sold} TH/S</span>
                  </div>
                  <div>
                     <span>{I18n.t('已售出')}</span>
                     <span>{item.sold} TH/S</span>
                  </div>
               </div>
               <div className="progress">
                  <div
                     className="inner"
                     style={{
                        width: getPercent() + '%',
                        borderRight:
                           Number(getPercent()) && getPercent() < 3 ? '8px solid #F7A62C' : null,
                     }}
                  >
                     {getPercent() > 10 && <span className="text">{getPercent()}%</span>}
                  </div>
                  {getPercent() <= 10 && <span className="text1">{getPercent()}%</span>}
               </div>
               <div className="head-right-bottom">
                  <div className="left">
                     <span>{I18n.t('价格')}</span>
                     <span>{formatNumber(item.price - item.act_price, 4)} USDT</span>
                     {Number(item.act_price) > 0 && (
                        <span className="price">{item.price} USDT</span>
                     )}
                  </div>
                  <Button type="primary" className="btn" onClick={handleClick}>
                     {I18n.t('立即购买')}
                  </Button>
                  {/* <div className="right" onClick={handleClick}>
                     <span>{I18n.t('立即购买')}</span>
                     {Number(item.act_price) > 0 && (
                        <div className="reduce">
                           {language === 'zh' && <span>{I18n.t('立减')} </span>}
                           <span>${item.act_price}</span>
                           {language === 'en' && <span> {I18n.t('立减')}</span>}
                        </div>
                     )}
                  </div> */}
               </div>
            </div>
         </div>
      </div>
   );
}
