import React from 'react';
import I18n from '../../language';
import { typeEnum } from '../../services/user';
import { useSelector } from 'react-redux';

export default function Details({ item }) {
   const { language } = useSelector(store => store.user);
   return (
      <div className="section-details">
         <div className="box-cell section-details-item">
            <div className="item-title">{I18n.t('套餐详情')}</div>
            <div className="item-side">
               <div>
                  <span>{I18n.t('套餐类型')}</span>
                  <span>{I18n.t(typeEnum[item.type]?.text)}</span>
               </div>
               <div className="mleft">
                  <span>{I18n.t('套餐周期')}</span>
                  <span>
                     {item.effect_time}
                     {language === 'zh' ? '' : ' '}
                     {I18n.t('天')}
                  </span>
               </div>
               <div className="mleft">
                  <span>{I18n.t('产出币种')}</span>
                  <span>{item.currency}</span>
               </div>
               <div className="mleft">
                  <span>{I18n.t('打币矿池')}</span>
                  <span>F2Pool</span>
               </div>
            </div>
            <div className="item-side">
               <div>
                  <span>{I18n.t('算力费单价')}</span>
                  <span>
                     {item.hashrate_price}USDT/T/{I18n.t('天')}
                  </span>
               </div>
               <div className="mleft">
                  <span>{I18n.t('电费单价')}</span>
                  <span>
                     {item.electric_price}USDT/T/{I18n.t('天')}
                  </span>
               </div>
               <div className="mleft">
                  <span>{I18n.t('生效时间')}</span>
                  <span>{I18n.t('24小时内')}</span>
               </div>
               <div className="mleft">
                  <span>{I18n.t('打币周期')}</span>
                  <span>{I18n.t('每日打币')}</span>
               </div>
            </div>
            {item.type === 'speeded' && (
               <div className="item-side">
                  <div>
                     <span>{I18n.t('回本后平台分成')}</span>
                     <span>{(item.recovery_devidend_ratio * 100).toFixed(2)}%</span>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
}
