import React, { useRef, useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../store/user';
import I18n from '../language';
import SvgIcon from './SvgIcon';
import logoImg from '../assets/imgs/header/logo.png';
import DarkImg from '../assets/imgs/header/logo_white.png';
export default function Home({ onChangeLang, onClick, theme = 'light' }) {
   const [activeKey, setActiveKey] = useState('platform');
   const items = [
      {
         key: 'platform',
         label: `${I18n.t('首页')}`,
      },
      {
         key: 'product',
         label: `${I18n.t('产品介绍')}`,
      },
      {
         key: 'mine',
         label: `${I18n.t('矿场介绍')}`,
      },
      {
         key: 'download',
         label: `${I18n.t('App下载')}`,
      },
      {
         key: 'help',
         label: `${I18n.t('帮助中心')}`,
      },
      {
         key: 'contact',
         label: `${I18n.t('联系我们')}`,
      },
   ];

   const params = useParams();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { language } = useSelector(store => store.user);

   function onChange(key) {
      if (activeKey === key) return;
      onClick(key);
      setActiveKey(key);
   }

   function handleToggle() {
      onChangeLang();
   }

   useEffect(() => {
      if (params.key) {
         onChange(params.key);
      }
   }, [params]);

   return (
      <div className={theme + ' header'}>
         <div className={['header-content'].join(' ')}>
            <img
               src={logoImg}
               alt=""
               style={{ cursor: 'pointer', marginRight: 40 }}
               onClick={() => {
                  navigate('/');
               }}
            />
            <div className={theme + ' header-center'}>
               {theme !== 'dark' ? (
                  <Tabs
                     activeKey={activeKey}
                     items={items}
                     onChange={onChange}
                     className={['tabs', language === 'en' ? 'tabs-reset' : '']}
                  />
               ) : null}
            </div>
            <div className="header-right">
               {theme !== 'dark' ? (
                  <div
                     className="regist"
                     onClick={() => {
                        navigate('/register');
                     }}
                  >
                     {I18n.t('注册')}
                  </div>
               ) : null}
               <div className={theme + ' lang'} onClick={handleToggle}>
                  <SvgIcon name="language"></SvgIcon>
                  {language === 'en' ? I18n.t('简体中文') : 'English'}
               </div>
            </div>
         </div>
      </div>
   );
}
