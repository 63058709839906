/**
 * 多语言配置文件
 */
import I18n from 'i18n-js';
import zh from './zh';
import en from './en';
import { store } from '../store';

const { language } = store.getState().user;
I18n.locale = language;

//监听语言改变
store.subscribe(() => {
   const { language } = store.getState().user;
   if (language !== I18n.locale) {
      I18n.locale = language;
   }
});

I18n.fallbacks = true;
I18n.translations = {
   zh,
   en,
};

export default I18n;
