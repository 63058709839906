import React, { forwardRef } from 'react';
import img1 from '../../assets/imgs/mine/bg1.png';
import img2 from '../../assets/imgs/mine/bg2.png';
import I18n from '../../language';
import { Carousel, Radio } from 'antd';
import { useSelector } from 'react-redux';

const Mine = forwardRef((props, ref) => {
   const { language } = useSelector(store => store.user);
   const mineList = [
      {
         img: img1,
         des: I18n.t('矿场介绍1'),
         path: I18n.t('北美基地'),
         area: '3000m²',
         energy: I18n.t('风能、太阳能'),
         total: '70MW',
      },
      {
         img: img2,
         des: I18n.t('矿场介绍2'),
         path: I18n.t('中亚基地'),
         area: '8000m²',
         energy: I18n.t('水力能源'),
         total: '130MW',
      },
   ];
   return (
      <div className="mine" ref={ref}>
         <Carousel dotPosition="right" autoplay>
            {mineList.map(data => {
               return (
                  <div className="item" key={data.img}>
                     <div className="left">
                        <h2 style={{ color: '#fff', margin: 0 }}>{I18n.t('矿场介绍')}</h2>
                        <div className="path">{data.path}</div>
                        <div className="list">
                           <div>
                              <span>{I18n.t('矿场面积')}</span>
                              <br />
                              <span>{data.area}</span>
                              <div className="icon"></div>
                           </div>
                           <div>
                              <span>{I18n.t('能源')}</span>
                              <br />
                              <span>{data.energy}</span>
                              <div className="icon"></div>
                           </div>
                           <div>
                              <span>{I18n.t('总容量')}：</span>
                              <br />
                              <span>{data.total}</span>
                              <div className="icon"></div>
                           </div>
                        </div>
                        <div className="des-wp">
                           <div className="sub">{I18n.t('介绍')}：</div>
                           <div className="des">{data.des}</div>
                        </div>
                     </div>
                     <img src={data.img} alt="" className="bg" />
                  </div>
               );
            })}
         </Carousel>
      </div>
   );
});

export default Mine;
