import React, { forwardRef } from 'react';
import I18n from '../../language';
import { useSelector } from 'react-redux';
import phoneImg from '../../assets/imgs/download/phone.png';
import codeImg from '../../assets/imgs/download/qrcode1.png';
import iosImg from '../../assets/imgs/download/1.png';
import apkImg from '../../assets/imgs/download/2.png';
import googleImg from '../../assets/imgs/download/3.png';
const BASE_URL = process.env.REACT_APP_API_URL;

const suffix = BASE_URL.split('.')[2];

const Download = forwardRef((props, ref) => {
   const { language } = useSelector(store => store.user);

   return (
      <div className="download view" ref={ref}>
         <div className="left">
            <img src={phoneImg} alt="" />
         </div>
         <div className="right">
            <div className="qr-code">
               <div className="text">
                  <h2 style={{ margin: 0 }}>{I18n.t('App下载')}</h2>
                  <h3>{I18n.t('开始您的挖矿之旅')}</h3>
               </div>
               <div className="code">
                  <img src={codeImg} alt="" />
                  <p>{I18n.t('扫描下载')}</p>
               </div>
            </div>
            <div className="download">
               <div className="label">{I18n.t('IOS下载')}</div>
               <div className="model">
                  <img src={iosImg} alt="" />
               </div>
               <p>
                  {I18n.t('您有海外账号可直接在App Store下载使用，没有海外账号的请参考')}
                  <a
                     target="_blank"
                     rel="noopener noreferrer"
                     href="https://bitrooapps-prod.oss-cn-hongkong.aliyuncs.com/docs/%E5%A6%82%E4%BD%95%E6%B3%A8%E5%86%8C%E6%B5%B7%E5%A4%96Apple%20ID.pdf"
                  >
                     {I18n.t('Bitroo App安装指南')}
                  </a>
               </p>
               <div className="label" style={{ marginTop: '50px' }}>
                  {I18n.t('Android下载')}
               </div>
               <div className="model">
                  <img src={apkImg} alt="" />
                  <img src={googleImg} alt="" style={{ marginLeft: '20px' }} />
               </div>
            </div>
         </div>
         {/* <div className="download-content">
            <div className="left">
               <div className="title">
                  <img src={imgL} alt="" />
                  <span>{I18n.t('App下载')}</span>
                  <img src={imgR} alt="" />
               </div>
               <div className="qrcode">
                  <img src={suffix === 'com' ? img3 : img5} alt="" />
               </div>
               <span className="side_1">{I18n.t('扫描下载')}</span>
               <span className="side_2">iOS & Android</span>
               <div className="side_3">
                  <div className="android">
                     <img src={img} alt="" />
                     <div>
                        <span>Download On the</span>
                        <span className="down">Google Play</span>
                     </div>
                  </div>
                  <div className="ios">
                     <img src={phoneImg} alt="" />
                     <div>
                        <span>Download On the</span>
                        <span className="down">APP Store</span>
                     </div>
                  </div>
               </div>
            </div>
            <img src={phoneImg} alt="" className="img" />
         </div> */}
      </div>
   );
});

export default Download;
