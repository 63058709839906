import React from 'react';
import I18n from '../../language';

export default function Introduce() {
   return (
      <div className="section-introduce">
         <div className="box-cell alert">
            <span className="title">{I18n.t('套餐说明')}</span>
            <span className="text">{I18n.t('免责声明1')}</span>
            <span className="text">{I18n.t('免责声明2')}</span>
            <span className="title">{I18n.t('无法退款声明')}</span>
            <span className="text">{I18n.t('免责声明3')}</span>
            <span className="title">{I18n.t('不可控风险声明')}</span>
            <span className="text">{I18n.t('免责声明4')}</span>
            <span className="title">{I18n.t('使用范围声明')}</span>
            <span className="text">{I18n.t('免责声明5')}</span>
         </div>
      </div>
   );
}
