import React from 'react';
import { useSelector } from 'react-redux';

function PrivacyPolicy() {
   const { language } = useSelector(store => store.user);

   if (language === 'zh') {
      document.title = '隐私策略';
   } else {
      document.title = 'Privacy Policy';
   }

   return (
      <div>
         {language === 'zh' && (
            <p style={{ margin: 10, fontSize: 14, lineHeight: '26px' }}>
               <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16 }}>隐私政策</div>
               <br />
               欢迎使用Bitroo！
               <br />
               我们尊重用户和网站访问者（统称为“您”）的隐私，并承诺尽力保护您的线上隐私。本隐私政策概述了Bitroo及其关联实体（以下称为“Bitroo”或“我们”）管理、收集、维护和处理使用者个人信息的流程。
               <br />
               我们可能不时更新本隐私政策。我们会在此页面上发布新的隐私政策来通知您任何变更。我们建议您定期查看此隐私政策来了解变更。本隐私政策的变更自其发布在此网页之时生效。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  一、信息收集
               </div>
               我们可能会使用您的个人信息来提供和改进Bitroo向您提供的矿机共用服务（“服务”），并改善您在本网站上的使用体验。“个人信息”是指与被识别或可识别的人有关的任何信息。当您为使用服务创建帐户时，我们会收集您提供给我们的信息。我们还使用各种技术来收集和存储信息，包括Cookie、图元标签、本机存放区（如流览器网路存储或应用程式信息缓存、信息库和服务器日志）。
               <br />
               您使用服务或本网站，即表示您同意我们按照本隐私政策收集和使用信息。除非本隐私政策另有规定，否则本隐私政策中使用的术语与我们的服务协议具有相同的含义。
               <br />
               1、注册
               <br />
               创建帐户时，需要您提供信息，包括您的电子邮箱，电话，密码或其他信息。
               <br />
               如果您拒绝提供上述信息，我们将无法向您提供服务。
               <br />
               2、支付信息
               <br />
               您的支付信息将发送至我们的合作厂商支付处理商，我们存储您的加密钱包位址，我们可以通过我们的合作厂商支付处理商访问并保留使用者信息。
               <br />
               3、日志信息
               <br />
               Bitroo会在您访问我们的网站和登录您的帐户时收集您的流览器发送的信息（“日志信息”）。此日志信息可能包括以下信息：您的电脑的互联网协定（“IP”）位址、流览器类型、流览器版本、您访问的服务的页面、存取时间和日期、在这些页面上花费的时间和其他统计信息。此外，我们可能会使用Google
               Analytics等合作厂商服务来收集、监控和分析此类信息，以提升我们服务的功能性。这些合作厂商服务提供者有自己的隐私政策来说明他们如何使用此类信息。这些合作厂商服务提供者只能在代表我们执行任务所需时查看使用您的个人信息。
               <br />
               4、消息推送
               <br />
               我们提供的消息推送功能由合作厂商提供技术支援。为了实现消息推送的即时性，以及实现基于用户画像的智慧推送和基于线下位置信息的应景推送功能，我们需要收集、使用您的下列信息：
               <br />
               1）创建使用者标识：为创建推送服务所需的使用者标识，我们可能需要收集以下设备信息：如IMEI、Android
               ID、设备序号、MAC、Android Advertisement
               ID、IMSI等。上述信息将被用于生成唯一的推送目标id，即CID。
               <br />
               （2）提高推送能力：为提供更好的推送服务和体验，如使用厂商系统推送能力等，我们可能需要收集以下设备信息：设备厂商、设备品牌、设备型号、Android版本、Rom信息。
               <br />
               （3）智慧推送功能：为实现基于用户画像进行精细化推送运营，为您推荐更符合您需要的推送内容，我们可能需要收集以下应用信息：APP安装清单、APP活跃清单信息。
               <br />
               5、我们如何使用您的信息
               <br />
               我们可能会使用您提供的个人信息用于下列目的：
               <br />
               （1）向您展示我们的网站及其内容;
               <br />
               （2）将您识别为我们系统中的使用者;
               <br />
               （3）处理您的订单;
               <br />
               （4）提供客户服务;
               <br />
               （5）回应您的要求;
               <br />
               （6）为您提供产品更新;
               <br />
               （7）改善我们的网站;
               <br />
               （8）发送与我们产品相关的新闻通讯，调查，优惠和其他宣传材料;
               <br />
               （9）与您沟通;
               <br />
               （10）管理风险并保护本网站，我们的服务和您免受欺诈;
               <br />
               （11）遵守所有适用的法律和法规，并执行本网站和Bitroo服务协定;
               <br />
               （12）以及其他征得您同意的目的。
               <br />
               6、关于Cookie的信息
               <br />
               为了使您获得更好的网站访问体验，我们使用“Cookie”来允许本网站识别您的流览器并存储使用者偏好和其他信息。如果您的流览器或流览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。但如果您这么做，在某些情况下可能会影响您访问我们网站的体验或无法使用我们部分的服务。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  二、合作厂商服务提供者
               </div>
               我们可能会聘请合作厂商公司为我提供业务相关服务。这些合作厂商只能在为我们提供服务的期间访问您的个人信息，并且有义务不得透露或将其用于任何其他目的。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  三、如果您是欧洲经济区（EEA）的居民
               </div>
               如果您是欧洲经济区（EEA）的居民，根据适用法律法规您拥有一些特定的信息保护权利。我们的目标是采取合理的措施，允许您更正，修改，删除或限制您的个人信息的使用。如果您希望了解我们持有的关于您的个人信息以及您希望将您的个人信息从我们的系统中删除，请与我们联系。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  四、信息安全
               </div>
               虽然我们致力于保护我们的网站和服务，但您有责任保护和维护您的个人信息安全，并验证我们维护的有关您的个人信息是否准确和最新。您必须防止未经授权访问您的帐户。使用共用电脑时请务必登出，并且不要向未经授权的人员披露您的帐户密码和任何其他帐户注册信息。
               然而，没有一个通过网际网路传输的方法或电子存储方法是100％安全的。因此，在我们尽力保护您的个人信息的同时，我们无法保证其绝对的安全性。我们无法保证您传输到我们网站和/或通过我们的服务的个人信息的安全性。任何个人信息的传输均由您自行承担风险。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  五、国际信息传输
               </div>
               通过提交您的信息，您同意您的信息（包括个人信息）可能会传输和保存到您所在国家/地区管辖范围之外的电脑和系统。若发生该等传输，我们会尽力确保您的信息得到和您所在国家/地区规定的程度基本一致的保护。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  六、合作厂商链接
               </div>
               我们的服务可能包含链接指向非我们运营的合作厂商网站。如果您点击合作厂商链接，您将被引导至该合作厂商网站。本隐私政策不适用于其他合作厂商网站。我们强烈建议您查看所访问的网站的隐私政策。Bitroo无法管控任何合作厂商网站或服务的内容和隐私政策，也不承担任何责任。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  七、儿童隐私
               </div>
               我们不会故意从13岁以下的人士收集或维护我们网站上的个人信息，并且我们网站的任何部分都不会针对13岁以下的人士。如果您未满13岁，请不要以任何方式使用或访问本网站。
               <br />
               当我们得知我们无意中收集了13岁以下的人士的个人信息时，我们会根据适用法律法规进行删除或采取其他合适的措施。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  八、个人信息使用目的变更
               </div>
               如果我们决定将您的个人信息用于本隐私政策声明以外的任何其他目的，我们将进行通知并为您提供一个有效的退出途径，供您选择不让自己的个人信息用于本隐私政策规定外的其他目的。
               <br />
               我们可能会向您发送有关新服务和新活动的电子邮件。您可以在我们发送的每封电子邮件中选择“取消订阅”功能来拒绝接收Bitroo的宣传性电子邮件。但是，您同意使用服务的使用者应继续接收与服务相关的其他重要电子邮件。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  九、信息保存时间
               </div>
               除非适用法律法规有存留要求，我们只会在达成本政策所述目的所需的期限内保留和存储您的个人信息。在法律允许的情况下，我们可能会存储一定的个人信息用于备案或通过法律途径保障自身的权益。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  十、如何了解，获取，更改或删除您的个人信息
               </div>
               如果您想确认我们正在处理您的信息，访问我们可能拥有的有关您的个人信息，更正我们收集的您的个人信息，或删除我们收集的您的个人信息，您可以使用本隐私政策规定的联系方式与我们联系。
               <br />
               对我们而言，确保我们收集的有关您的个人信息的准确性，完整性和最新性非常重要。如果您发现您的信息不准确或已过时，请告知我们，我们会进行相应的更改。但是，如果我们认为更改会违反任何法律或法律要求，或导致信息不正确，我们可能无法接受更改信息的请求。
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  十一、如何联系我们
               </div>
               如果您对本隐私政策有任何疑问，请发送电子邮件至 support@bitroo.com 与我们联系。
            </p>
         )}

         {language !== 'zh' && (
            <p style={{ margin: 10, fontSize: 14, lineHeight: '26px' }}>
               <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16 }}>
                  Privacy Policy
               </div>
               <br />
               Welcome to Bitroo!
               <br />
               We respect the privacy of our users and website visitors (collectively, "you") and
               are committed to doing our best to protect your online privacy. This Privacy Policy
               outlines the process by which Bitroo and its affiliated entities (hereinafter
               referred to as "Bitroo" or "we") manage, collect, maintain and process users'
               personal information.
               <br />
               We may update this Privacy Policy from time to time. We will notify you of any
               changes by posting the new Privacy Policy on this page. We recommend that you
               periodically review this Privacy Policy for changes. Changes to this Privacy Policy
               are effective when they are posted on this web page.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  1. Information collection
               </div>
               We may use your personal information to provide and improve the mining machine
               sharing service ("Service") provided by Bitroo to you, and to improve your experience
               on this website. "Personal Information" means any information relating to an
               identified or identifiable person. We collect information that you provide to us when
               you create an account to use the Services. We also use a variety of technologies to
               collect and store information, including cookies, meta tags, local storage such as
               browser web storage or application caches, repositories and server logs.
               <br />
               By using the Services or the Site, you consent to our collection and use of
               information in accordance with this Privacy Policy. Unless otherwise specified in
               this privacy policy, the terms used in this privacy policy have the same meanings as
               our service agreement.
               <br />
               1. Registration
               <br />
               When creating an account, you are required to provide information, including your
               email address, phone number, password or other information.
               <br />
               If you refuse to provide the above information, we will not be able to provide
               services to you.
               <br />
               2. Payment information
               <br />
               Your payment information will be sent to our partner payment processor, we store your
               encrypted wallet address, and we can access and retain user information through our
               partner payment processor.
               <br />
               3. Log information
               <br />
               Bitroo collects information from your browser when you visit our website and when you
               log into your account ("Log Information"). This log information may include the
               following information: your computer's Internet Protocol ("IP") address, browser
               type, browser version, the pages of the Service that you visit, the time and date of
               access, the time and date spent on those pages, time and other statistics. In
               addition, we may use partner services such as Google Analytics to collect, monitor
               and analyze such information to improve the functionality of our services. These
               partner vendor service providers have their own privacy policies that describe how
               they use such information. These partner service providers can only view and use your
               personal information as necessary to perform tasks on our behalf.
               <br />
               4. Message push
               <br />
               The message push function we provide is provided by the partner manufacturer to
               provide technical support. In order to realize the immediacy of message push, as well
               as the smart push based on user portrait and the occasional push function based on
               offline location information, we need to collect and use your following information:
               <br />
               (1) Create user ID: In order to create the user ID required for the push service, we
               may need to collect the following device information: such as IMEI, Android ID,
               device serial number, MAC, Android Advertisement ID, IMSI, etc. The above information
               will be used to generate a unique push target id, namely CID.
               <br />
               (2) Improve push capability: In order to provide better push service and experience,
               such as using the manufacturer's system push capability, we may need to collect the
               following device information: device manufacturer, device brand, device model,
               Android version, Rom information.
               <br />
               (3) Smart push function: In order to implement refined push operations based on user
               portraits and recommend push content that better meets your needs, we may need to
               collect the following application information: APP installation list, APP active list
               information.
               <br />
               5. How we use your information:
               <br />
               We may use the personal information you provide for the following purposes:
               <br />
               (1) To present our website and its content to you;
               <br />
               (2) To identify you as a user in our system;
               <br />
               (3) To process your orders;
               <br />
               (4) To provide customer service;
               <br />
               (5) To respond to your requests;
               <br />
               (6) To provide you with product updates;
               <br />
               (7) To improve our website;
               <br />
               (8) To send newsletters, surveys, promotions, and other marketing materials related
               to our products;
               <br />
               (9) To communicate with you;
               <br />
               (10) To manage risks and protect this website, our services, and you from fraud;
               <br />
               (11) To comply with all applicable laws and regulations and enforce this website and
               Bitroo service agreements;
               <br />
               (12) And for any other purposes you consent to.
               <br />
               6. Information about cookies
               <br />
               To provide you with a better website experience, we use "cookies" to allow this
               website to recognize your browser and store user preferences and other information.
               If your browser or browser add-on services allow, you can modify the acceptance of
               cookies or reject our cookies. However, if you do so, in some cases it may affect
               your experience of visiting our website or you may not be able to use some of our
               services.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  2. Partner service provider
               </div>
               We may engage a partner vendor company to provide me with business-related services.
               These partners can only access your personal information during the period of
               providing services to us, and are obliged not to disclose or use it for any other
               purpose.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  3. If you are a resident of the European Economic Area (EEA)
               </div>
               If you are a resident of the European Economic Area (EEA), you have certain data
               protection rights under applicable laws and regulations. Our goal is to take
               reasonable steps to allow you to correct, amend, delete or limit the use of your
               personal information. If you wish to know what personal information we hold about you
               and if you wish to have your personal information deleted from our systems, please
               contact us.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  4. Information security
               </div>
               While we are committed to protecting our website and services, you are responsible
               for protecting and maintaining the security of your personal information and for
               verifying that the personal information we maintain about you is accurate and up to
               date. You must prevent unauthorized access to your account. Always log out when using
               a shared computer and do not disclose your account password and any other account
               registration information to unauthorized persons.
               <br />
               However, no method of transmission over the Internet or method of electronic storage
               is 100% secure. Therefore, while we do our best to protect your personal information,
               we cannot guarantee its absolute security. We cannot guarantee the security of your
               personal information transmitted to our website and/or through our services. Any
               transmission of personal information is at your own risk.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  5. International information transmission
               </div>
               By submitting your information, you agree that your information, including personal
               information, may be transferred and stored on computers and systems outside the
               jurisdiction of your country. Where such transfers occur, we will endeavor to ensure
               that your information is protected to a degree substantially consistent with that
               required in your country.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  6. Links of cooperative manufacturers
               </div>
               Our Services may contain links to partner sites that are not operated by us. If you
               click on a partner vendor link, you will be directed to that partner vendor's
               website. This privacy policy does not apply to other partner vendors' websites. We
               strongly recommend that you review the privacy policies of the websites you visit.
               Bitroo has no control over, and assumes no responsibility for, the content and
               privacy policies of any partner site or service.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  7. Children's privacy
               </div>
               We do not knowingly collect or maintain personal information on our site from, and no
               part of our site is directed at, anyone under the age of 13. If you are under the age
               of 13, please do not use or access this website in any way.
               <br />
               When we learn that we have inadvertently collected personal information from persons
               under the age of 13, we will delete it or take other appropriate measures in
               accordance with applicable laws and regulations.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  8. Change of purpose of use of personal information
               </div>
               If we decide to use your personal information for any other purpose than stated in
               this Privacy Policy, we will notify you and provide you with an effective way to opt
               out of having your personal information used for purposes other than those specified
               in this Privacy Policy other purposes.
               <br />
               We may send you emails about new services and events. You may opt out of receiving
               promotional emails from Bitroo by selecting the "unsubscribe" feature in each email
               we send. However, you agree that users of the Services should continue to receive
               other important emails related to the Services.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  9. Information retention time
               </div>
               Unless applicable laws and regulations require retention, we will only retain and
               store your personal information for the period necessary to achieve the purposes
               stated in this policy. Where permitted by law, we may store certain personal
               information for filing or to protect our own rights through legal channels.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  10. How to understand, obtain, change or delete your personal information
               </div>
               If you wish to verify that we are processing your information, accessing any personal
               information we may have about you, correcting the personal information we have
               collected from you, or have us delete the personal information we have collected from
               you, you may contact us using the contact details provided in this Privacy Policy.
               <br />
               For us, ensuring the accuracy, completeness, and currency of the personal information
               we collect about you is of utmost importance. If you find that your information is
               inaccurate or outdated, please inform us, and we will make the necessary changes.
               However, if we believe that the changes would violate any laws or legal requirements
               or result in incorrect information, we may be unable to accept the request to change
               the information.
               <br />
               <div style={{ fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 8 }}>
                  11. How to contact us
               </div>
               If you have any questions about this Privacy Policy, please contact us at
               support@bitroo.com.
            </p>
         )}
      </div>
   );
}

export default PrivacyPolicy;
