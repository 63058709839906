import React from 'react';
import I18n from '../../language';
import SvgIcon from '../../components/SvgIcon';
import { useSelector } from 'react-redux';

export default function SpeededDes({ item }) {
   const tableKeys = ['header', 'period', 'orientation', 'day', 'service_day', 'invest'];
   const keys2Label = {
      day: '静态回本天数',
      header: '',
      invest: '期初投入',
      period: '产品周期',
      service_day: '服务费缴纳天数',
      orientation: '产品定位',
   };
   const { language } = useSelector(store => store.user);
   return (
      <div className="section-break-event">
         <div className="box-cell mb40 alert">
            <div className="item-title">{I18n.t('套餐简介')}</div>
            <p className="text">{item.package_introduction}</p>
         </div>
         <div className="box-cell mb40 break-event-wp speeded-wp">
            <div className="item-title mb25">{I18n.t('产品优势')}</div>
            <div className="sub-title mb25">{item.product_advantages}</div>
            <div className="content">
               <div className="item">
                  {tableKeys.map(key => (
                     <div>{keys2Label[key] ? I18n.t(keys2Label[key]) : ' '}</div>
                  ))}
               </div>
               {item.extend.map(tx => (
                  <div className="item">
                     {tableKeys.map(key => (
                        <div>{tx[key]}</div>
                     ))}
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
}
