import React from 'react';
import Input from './index';
import { getArea } from '../../services/user';
import I18n from '../../language';
import './index.scss';

const SelectArea = ({ onSelect }) => {
   const [dataList, setDataList] = React.useState([]);
   const [hotList, setHotList] = React.useState([]);
   const [searchVal, setSearchVal] = React.useState('');
   const [searchList, setSearchList] = React.useState([]);
   const [searchTimer, setSearchTimer] = React.useState(0);

   React.useEffect(() => {
      getData();
   }, []);
   React.useEffect(() => {
      if (searchVal.trim().length > 0) {
         doSearch();
      } else {
         setSearchList([]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchVal]);

   const doSearch = () => {
      if (searchTimer) {
         clearTimeout(searchTimer);
         setSearchTimer(0);
      }
      const timer = setTimeout(() => {
         const list = [];
         const val = searchVal.trim().toLocaleLowerCase();
         dataList.forEach(item => {
            if (
               item.name_zh.toLocaleLowerCase().includes(val) ||
               item.name.toLocaleLowerCase().includes(val) ||
               `${item.area_code}`.includes(val)
            ) {
               list.push(item);
            }
         });
         setSearchList(list);
      }, 500);
      setSearchTimer(timer);
   };

   const selectClick = data => {
      if (onSelect && typeof onSelect === 'function') {
         onSelect(data.area_code);
      }
   };

   const Item = ({ data }) => {
      return (
         <div className="area-item" onClick={() => selectClick(data)}>
            <div className="area-name">{data[`name${I18n.locale === 'zh' ? '_zh' : ''}`]}</div>
            <div>+{data.area_code}</div>
         </div>
      );
   };
   const getData = async () => {
      const res = await getArea();
      if (res.data?.code === 0) {
         const data = res.data.data;
         const hot = [];
         data.forEach(item => {
            if (item.hot) {
               hot.push(item);
            }
         });
         setHotList(hot);
         setDataList(data);
      }
   };

   const searchValChange = text => {
      setSearchVal(text);
   };

   return (
      <div className="select-area">
         <div className="select-area-search-box">
            <Input
               placeholder={I18n.t('搜索地区或区号')}
               type="search"
               value={searchVal}
               onChange={searchValChange}
               className="select-area-search"
            ></Input>
         </div>
         <div className="select-area-lit">
            {searchVal.trim().length <= 0 && (
               <React.Fragment>
                  {hotList.length > 0 && (
                     <>
                        <div className="area-title">{I18n.t('热门国家地区')}</div>
                        {hotList.map(item => {
                           return <Item key={`hot_${item.id}`} data={item}></Item>;
                        })}
                     </>
                  )}
                  <div className="area-title">{I18n.t('所有国家地区')}</div>
                  {dataList.map(item => {
                     return <Item key={`list_${item.id}`} data={item}></Item>;
                  })}
               </React.Fragment>
            )}
            {searchVal.trim().length > 0 && (
               <>
                  {searchList.map(item => {
                     return <Item key={`search_${item.id}`} data={item}></Item>;
                  })}
               </>
            )}
         </div>
      </div>
   );
};

export default SelectArea;
