import React, { useEffect, useState } from 'react';
import I18n from '../../language';
import { useSelector } from 'react-redux';
import { getRecoveryDays } from '../../services/user';
import ReactECharts from 'echarts-for-react';

export default function Income({ item }) {
   const [option, setOption] = useState({});
   const [days, setDays] = useState('');
   const { language } = useSelector(store => store.user);

   function getData() {
      getRecoveryDays({ id: item.id, sale_nums: item.min_orders })
         .then(res => {
            if (res.data.code === 0) {
               const data = res.data.data;
               const obj = {
                  confine: true,
                  legend: {},
                  grid: {
                     x: '6%', //x 偏移量
                     y: '12%', // y 偏移量
                     width: '86%', // 宽度
                     height: '72%', // 高度
                  },
                  xAxis: {
                     axisLine: {
                        lineStyle: {
                           color: '#CCCCCC',
                        },
                     },
                     splitLine: { show: false },
                     axisLabel: { show: false },
                     axisTick: {
                        show: false,
                     },
                  },
                  yAxis: {
                     axisLine: {
                        show: false,
                     },
                     // 隐藏刻度线
                     axisTick: {
                        show: false,
                     },
                     splitLine: { show: false },
                     axisLabel: { show: false },
                  },
                  visualMap: {
                     show: false,
                     type: 'piecewise',
                     dimension: 0,
                     seriesIndex: 0,
                     pieces: [
                        {
                           gt: Number(data.return_time),
                           color: '#F7B52C',
                        },
                        {
                           gt: data.return_time < 0 ? data.return_time : 0,
                           lte: data.return_time < 0 ? 0 : Number(data.return_time),
                           color: '#52AF99',
                        },
                     ],
                  },

                  series: [
                     {
                        type: 'line',
                        data: [
                           [0, 0],
                           [Number(data.return_time), Number(data.return_time)],
                           [item.effect_time, item.effect_time],
                        ],
                        label: {
                           show: true,
                           offset: [0, 0],
                           formatter: function (val) {
                              // if (val.dataIndex > 0) {
                              //    return val.data[0] + I18n.t('天');
                              // }
                           },
                        },
                        itemStyle: {},
                     },
                  ],
               };
               setOption(obj);
               setDays(data.return_time);
            }
         })
         .catch(e => {});
   }
   const getDes = type => {
      if (type === 'guaranteed') {
         return <p>{I18n.t('保本回本天数')}</p>;
      } else if (type === 'speeded') {
         return <p>{I18n.t('加速回本天数')}</p>;
      } else {
         return <p>{I18n.t('详情页2')}</p>;
      }
   };
   useEffect(() => {
      if (JSON.stringify(item) !== '{}') {
         getData();
      }
   }, [item]);

   return (
      <div className="section-income">
         <div className="box-cell section-income-item">
            <div className="caption">
               <span>{I18n.t('静态回本天数')}</span>
               <span>
                  {days}
                  {language === 'zh' ? '' : ' '}
                  {I18n.t('天')}
               </span>
            </div>
            <div className="income-item-side">
               <div className="left">
                  <ReactECharts
                     option={option}
                     notMerge={true}
                     lazyUpdate={true}
                     className="charts"
                  />
                  <div className="bottom">
                     <div className="day1">
                        <span></span>
                        <span>{I18n.t('回本天数')}</span>
                     </div>
                     <div className="day2">
                        <span></span>
                        <span>{I18n.t('周期天数')}</span>
                     </div>
                  </div>
               </div>
               <div className="right">
                  {getDes(item.type)}

                  <p>{I18n.t('详情页1')}</p>
               </div>
            </div>
         </div>
      </div>
   );
}
